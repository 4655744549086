import React from "react";

import { useRef } from "react";
import ConstraintedBox from "../common/ConstraintedBox";
import ResponsiveBox from "../common/ResponsiveBox";
import features from "../../data/features";
import promotion_features from "../../data/promotion_features";

const Promotion_Section1 = () => {
    const featuresRef = useRef(null);

    return (
        <ResponsiveBox
            classNames="bg-[var(--dialogColor)] min-h-[90vh] items-center justify-center"
            id="features"
            elementRef={featuresRef}
        >
            <ConstraintedBox classNames="p-4 py-12 sm:mt-10">
                <div className="text-center mx-auto text-xl sm:text-5xl font-medium">
                    Program Features
                </div>

                <div className="relative w-full grid grid-cols-1 transition duration-300 ease-in-out justify-items-center mt-12">
                    <div className="slide-container grid sm:grid-cols-2">
                        {/* <Slide> */}
                        {promotion_features.windowImages.map(
                            (slideImage, index) => {
                                return (
                                    <div key={index} className="pt-4 px-2">
                                        <img
                                            src={slideImage.url}
                                            alt="profile"
                                            width={600}
                                            height={1000}
                                        />
                                    </div>
                                );
                            }
                        )}
                        {/* </Slide> */}
                    </div>
                    <div className="slide-container grid sm:flex">
                        {/* <Slide> */}
                        {promotion_features.phoneImages.map(
                            (slideImage, index) => {
                                return (
                                    <div key={index} className="pt-4 px-2">
                                        <img
                                            src={slideImage.url}
                                            alt="profile"
                                            width={400}
                                            height={1000}
                                        />
                                    </div>
                                );
                            }
                        )}
                        {/* </Slide> */}
                    </div>

                    <div className="text-sm sm:text-3xl pt-10 text-left w-full">
                        <div className="text-2xl sm:text-4xl text-red-400 mb-4">
                            Features
                        </div>
                        <div>- App Store App</div>
                        <div>- Horses, Harness, Greyhounds</div>
                        <div>- Bookies Added, Sportsbet, Tab</div>
                        <div>- Horses, Harness, Greyhounds</div>
                        <div>
                            - Bonus Bet Amount, Bet Return Amount, Normal Bet
                            Amount{" "}
                        </div>
                        <div>- Bet History Tracker, Wins Losses, Profit.</div>
                        <div>- Unlimited Phones Connect</div>
                        <div>- 1 Computer Needed</div>
                        <div>
                            - IE Snare Removal so the bookies can't track your
                            device
                        </div>
                        <div>- GPS location spoofer built in.</div>
                        <div>
                            - Choose your bet delay how many seconds per account
                        </div>
                    </div>
                </div>
            </ConstraintedBox>
        </ResponsiveBox>
    );
};

export default Promotion_Section1;
