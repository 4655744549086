import React from "react";

import { useRef } from "react";
import ConstraintedBox from "../common/ConstraintedBox";
import ResponsiveBox from "../common/ResponsiveBox";
import features from "../../data/features";
import promotion_features from "../../data/promotion_features";

const Promotion_Section2 = () => {
    const featuresRef = useRef(null);

    return (
        <ResponsiveBox
            classNames="bg-[var(--bgColor)] min-h-[70vh] items-center justify-center"
            id="features"
            elementRef={featuresRef}
        >
            <ConstraintedBox classNames="p-4 py-12 sm:mt-10">
                <h1 className="text-center w-full">
                    Automation
                    <br />
                    Software Tools
                </h1>
                <div className="text-center w-full text-2xl text-green-400 pt-4">
                    Technology for bettors and syndicates to scale
                </div>
                <div className="text-center w-full text-2xl sm:text-3xl text-white pt-10">
                    SUPPORTS<span className="text-green-400 px-2">4</span>
                    BOOKIES SO FAR
                </div>
                <div className="flex m-auto pt-10">
                    {promotion_features.bookieImages.map(
                        (slideImage, index) => {
                            return (
                                <div key={index} className="pt-4 px-6">
                                    <img
                                        src={slideImage.url}
                                        alt="profile"
                                        width={100}
                                    />
                                </div>
                            );
                        }
                    )}
                </div>
            </ConstraintedBox>
        </ResponsiveBox>
    );
};

export default Promotion_Section2;
