import React from "react";
import "./App.css";
import "./globals.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./app/home/page";
import AccountsPage from "./app/accounts/page";
import SoftwarePage from "./app/software/page";
import WaitListPage from "./app/waitlist/page";
import AccountFormPage from "./app/account_form/page";
import { RecoilRoot } from "recoil";
import RatingPage from "./app/peoples_rating/page";
import PromotionPage from "./app/promotion_software/page";
import BotResults from "./app/live_racing_bot_results/page";
import SignUpBotPage from "./app/signupbot/page";

function App() {
    return (
        <div className="App">
            <RecoilRoot>
                <BrowserRouter>
                    <Routes>
                        <Route exact path="/" element={<HomePage />} />
                        <Route
                            exact
                            path="/accounts"
                            element={<AccountsPage />}
                        />
                        {/* <Route
                            exact
                            path="/software"
                            element={<SoftwarePage />}
                        /> */}
                        <Route
                            exact
                            path="/signupbot"
                            element={<SignUpBotPage />}
                        />
                        <Route
                            exact
                            path="/wait_list"
                            element={<WaitListPage />}
                        />
                        <Route
                            exact
                            path="/account_form"
                            element={<AccountFormPage />}
                        />
                        <Route
                            exact
                            path="/peoples_reviews"
                            element={<RatingPage />}
                        />
                        <Route
                            exact
                            path="/promotion_software"
                            element={<PromotionPage />}
                        />
                        <Route
                            exact
                            path="/_live_racing_bot_results"
                            element={<BotResults />}
                        />
                    </Routes>
                </BrowserRouter>
            </RecoilRoot>
        </div>
    );
}

export default App;
