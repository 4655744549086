import React from "react";

const AccountSection6 = () => {
    return (
        <div className=" bg-[var(--bgColor)] font-poppins bg-cover text-black">
            <div className="sm:w-[850px] mx-auto w-full">
                <div className="py-[20px] flex gap-10 justify-center">
                    <div>
                        <a
                            href="https://www.facebook.com/profile.php?id=61554319537947"
                            target="_blank"
                        >
                            <svg
                                preserveAspectRatio="none"
                                data-bbox="33.333 34 133.334 132.525"
                                viewBox="33.333 34 133.334 132.525"
                                height="40"
                                width="40"
                                xmlns="http://www.w3.org/2000/svg"
                                data-type="shape"
                                role="presentation"
                                aria-hidden="true"
                                fill="white"
                                aria-label=""
                            >
                                <g>
                                    <path
                                        d="M166.667 100.667C166.667 63.85 136.817 34 100 34s-66.667 29.85-66.667 66.667c0 33.275 24.384 60.858 56.25 65.858v-46.583H72.658v-19.275h16.925V85.983c0-16.708 9.95-25.941 25.184-25.941 7.291 0 14.925 1.3 14.925 1.3V77.75h-8.409c-8.283 0-10.866 5.142-10.866 10.417v12.5h18.491l-2.958 19.275h-15.533v46.583c31.875-5 56.25-32.583 56.25-65.858Z"
                                        fill-rule="evenodd"
                                    ></path>
                                </g>
                            </svg>
                        </a>
                    </div>
                    <div>
                        <svg
                            preserveAspectRatio="none"
                            data-bbox="31 31 137.5 137.5"
                            viewBox="31 31 137.5 137.5"
                            height="40"
                            width="40"
                            xmlns="http://www.w3.org/2000/svg"
                            data-type="shape"
                            role="presentation"
                            aria-hidden="true"
                            fill="white"
                            aria-label=""
                        >
                            <g>
                                <path
                                    d="M99.75 31C61.021 31 31 59.369 31 97.688c0 20.043 8.214 37.362 21.591 49.325a5.49 5.49 0 0 1 1.847 3.92l.375 12.229c.12 3.901 4.149 6.439 7.718 4.864l13.646-6.024a5.492 5.492 0 0 1 3.672-.27c6.27 1.724 12.945 2.643 19.901 2.643 38.729 0 68.75-28.369 68.75-66.688C168.5 59.37 138.479 31 99.75 31Zm-41.284 86.19L78.66 85.15c3.213-5.097 10.092-6.366 14.912-2.751l16.062 12.046a4.125 4.125 0 0 0 4.969-.014l21.693-16.463c2.896-2.198 6.675 1.267 4.737 4.342l-20.195 32.041c-3.213 5.097-10.092 6.366-14.912 2.751l-16.062-12.047a4.126 4.126 0 0 0-4.97.014l-21.692 16.464c-2.896 2.197-6.675-1.268-4.737-4.343Z"
                                    fill-rule="evenodd"
                                ></path>
                            </g>
                        </svg>
                    </div>
                </div>
                <div className="text-white pb-4">
                    © BetAllSoftware 2024. All rights reserved.
                </div>
            </div>
        </div>
    );
};

export default AccountSection6;
