export const getWeekday = (input) => {
    // Parse the input string into a Date object
    let date = new Date(input);

    // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    let dayOfWeek = date.getDay();

    // Array to map day of week index to its name
    const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

    // Return the name of the day of the week
    return daysOfWeek[dayOfWeek];
};

export function getWeekStartDate(date) {
    let diff = (7 + (date.getDay() - 1)) % 7;
    return new Date(date.getTime() - diff * 24 * 60 * 60 * 1000);
}
