import React from "react";
import AccountSection1 from "../../components/accounts/Section1";
import AccountSection2 from "../../components/accounts/Section2";
import NavBar from "../../components/navbar/NavBar";
import AccountSection3 from "../../components/accounts/Section3";
import AccountSection4 from "../../components/accounts/Section4";
import AccountSection5 from "../../components/accounts/Section5";
import AccountSection6 from "../../components/accounts/Section6";

// export default function AccountsPage() {
//     return (
//         <>
//             <NavBar />
//             <PageBox>
//                 <HomeSection1 />
//                 {/* <HomeSection2 /> */}
//                 <Footer bgType={1} />
//             </PageBox>
//         </>
//     );
// }

export default function AccountsPage() {
    return (
        <div>
            <NavBar />
            <div className="sm:mt-[95px] mt-[80px]">
                <AccountSection1 />
                <AccountSection2 />
                <AccountSection3 />
                <AccountSection4 />
                <AccountSection5 />
                <AccountSection6 />
            </div>
        </div>
    );
}
