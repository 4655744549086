import React from "react";

import { useState } from "react";
import PropTypes from "prop-types";

const ReadMoreText = ({ children, className, visibleTextCount = 150 }) => {
    const text = children;

    const [isReadMore, setIsReadMore] = useState(true);

    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };

    return (
        <p
            className={`w-full inline transition duration-500 ease-in-out slide_in ${className}`}
        >
            {text.length > visibleTextCount
                ? text.slice(0, isReadMore ? visibleTextCount : text.length)
                : text}
            {text.length > visibleTextCount ? (
                <span
                    onClick={toggleReadMore}
                    className=" text-green-400 cursor-pointer"
                >
                    {isReadMore ? "... read more" : " show less"}
                </span>
            ) : null}
        </p>
    );
};

ReadMoreText.propTypes = {
    children: PropTypes.string.isRequired,
    className: PropTypes.string,
    visibleTextCount: PropTypes.number,
};

export default ReadMoreText;
