import React from "react";
import PageBox from "../../components/common/PageBox";
import Footer from "../../components/home/Footer";
import NavBar from "../../components/navbar/NavBar";
import BettingHistory from "../../components/home/BettingHistory";

export default function BotResults() {
    return (
        <>
            <NavBar />
            <PageBox>
                <BettingHistory />
                {/* <Footer bgType={0} /> */}
            </PageBox>
        </>
    );
}
