import React, { useState } from "react";

const InputBox = ({
    type,
    value,
    setValue,
    classNames,
    placeholder,
    required,
    minLength,
    pattern,
    errorMessage,
}) => {
    const [error, setError] = useState("");

    const handleChange = (e) => {
        setValue(e.target.value);
        setError("");
    };

    const handleBlur = () => {
        if (required && !value?.trim()) {
            setError("This field is required");
        } else if (minLength && value.length < minLength) {
            setError(`Please enter at least ${minLength} characters`);
        } else if (pattern && !RegExp(pattern).test(value)) {
            setError(errorMessage || "Invalid input format");
        }
    };

    return (
        <>
            <input
                type={type}
                value={value}
                placeholder={placeholder}
                onChange={handleChange}
                onBlur={handleBlur}
                className={classNames}
                required={required}
            />
            {error && (
                <p className="error-message text-red-500">*&nbsp;{error}</p>
            )}
        </>
    );
};

export default InputBox;
