import React from "react";

import { useRef } from "react";
import ConstraintedBox from "../common/ConstraintedBox";
import ResponsiveBox from "../common/ResponsiveBox";
import features from "../../data/features";
import signupBotImages from "../../data/signupbot";

const HomeSection8 = () => {
    const featuresRef = useRef(null);

    return (
        <ResponsiveBox
            classNames="bg-[var(--bgColor)] min-h-[90vh] items-center justify-center"
            id="features"
            elementRef={featuresRef}
        >
            <ConstraintedBox classNames="p-4 py-12 sm:mt-10">
                {/* <div className="text-center mx-auto text-xl sm:text-5xl font-medium">
                    AI Betting Program Features
                </div> */}

                <div className="relative w-full grid grid-cols-1 transition duration-300 ease-in-out justify-items-center mt-12">
                    <div className="slide-container">
                        {/* <Slide> */}
                        {signupBotImages.slideImages.map(
                            (slideImage, index) => {
                                return (
                                    <div key={index} className="pt-4">
                                        <img
                                            src={slideImage.url}
                                            alt="profile"
                                            width={1000}
                                            height={100}
                                        />
                                    </div>
                                );
                            }
                        )}
                        {/* </Slide> */}
                    </div>
                </div>
            </ConstraintedBox>
        </ResponsiveBox>
    );
};

export default HomeSection8;
