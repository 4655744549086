import { Rating } from "@mui/material";
import React, { useEffect, useState } from "react";
import WrappedBox from "../common/WrappedBox";
import ReadMoreText from "../common/ReadMoreText";
import Column from "../common/Column";

export const RatingView = ({ reviews }) => {
    const [displayedData, setDisplayedData] = useState(null);
    const [visibleItemCount, setVisibleItemCount] = useState(2); // Change this as needed

    const handleLoadMore = () => {
        setVisibleItemCount((prevCount) => prevCount + 2);
    };

    useEffect(() => {
        setDisplayedData(reviews?.slice(0, visibleItemCount));
    }, [reviews, visibleItemCount]);
    return (
        <WrappedBox classes="justify-items-center grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 mt-12">
            {displayedData?.map((review, index) => {
                return (
                    <Column
                        key={`review-${index}`}
                        classes="bg-[var(--textColor10)] p-4 px-8 rounded-[var(--borderRadius)] w-full hover:bg-[var(--textColor30)]"
                    >
                        <div className="flex justify-between w-full items-center">
                            <img
                                src={
                                    review.selfie === ""
                                        ? "/images/empty_avatar.jpg"
                                        : review.selfie
                                }
                                alt={`selfie-${index}`}
                                width="100px"
                                height="auto"
                                className="borer-2 border-white rounded-full shadow-sm shadow-black aspect-square"
                            />
                            <Rating
                                name="read-only"
                                value={review.value}
                                readOnly
                            />
                        </div>
                        <div className="text-xl p-2">{review.writer}</div>
                        <ReadMoreText className="mt-4 text-[var(--textColorLight)] text-left">
                            {review.content}
                        </ReadMoreText>
                    </Column>
                );
            })}
            {visibleItemCount >= reviews?.length ? null : (
                <button onClick={handleLoadMore}>Load More</button>
            )}
        </WrappedBox>
    );
};
