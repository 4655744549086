import React from "react";
import PageBox from "../../components/common/PageBox";
import Footer from "../../components/home/Footer";
import NavBar from "../../components/navbar/NavBar";
import { Ratings } from "../../components/home/Ratings";

export default function RatingPage() {
    return (
        <>
            <NavBar />
            <PageBox>
                <Ratings value={4} />
                {/* <Footer bgType={1} /> */}
            </PageBox>
        </>
    );
}
