import React from "react";

import { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import navMenus from "../../data/navMenus";
import MenuItems from "./MenuItems";
import { Link, useNavigate } from "react-router-dom";

const NavBar = () => {
    const mobileMenuRef = useRef(null);
    const navRef = useRef();
    const navigate = useNavigate();

    const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

    const toggleMenu = () => {
        const cList = mobileMenuRef.current.classList;

        if (
            cList.contains("flex") &&
            cList.contains("opacity-1") &&
            cList.contains("z-[1000]") &&
            cList.contains("visible")
        ) {
            hideMobileMenu();
        } else {
            showMobileMenu();
        }
    };

    const showMobileMenu = () => {
        const cList = mobileMenuRef.current.classList;

        if (
            cList.contains("hidden") &&
            cList.contains("opacity-0") &&
            cList.contains("z-0") &&
            cList.contains("invisible")
        ) {
            cList.remove("hidden");
            cList.remove("opacity-0");
            cList.remove("z-0");
            cList.remove("invisible");
        }

        cList.add("flex");
        cList.add("opacity-1");
        cList.add("z-[1000]");
        cList.add("visible");
        setMobileMenuVisible(true);
    };

    const hideMobileMenu = (menu) => {
        if (menu && menu.path) {
            navigate(menu.path, { replace: true });
        }
        const cList = mobileMenuRef.current.classList;

        if (
            cList.contains("flex") &&
            cList.contains("opacity-1") &&
            cList.contains("z-[1000]") &&
            cList.contains("visible")
        ) {
            cList.remove("flex");
            cList.remove("opacity-1");
            cList.remove("z-[1000]");
            cList.remove("visible");
        }

        cList.add("hidden");
        cList.add("opacity-0");
        cList.add("z-0");
        cList.add("invisible");
        setMobileMenuVisible(false);
    };

    useOnClickOutside(navRef, hideMobileMenu);

    return (
        <div
            className="fixed w-screen h-auto z-[1000] top-0 bg-[var(--primaryColor20)]"
            ref={navRef}
        >
            <div className="h-full flex mx-auto px-10 py-6">
                <div className="w-full flex justify-between items-center">
                    <Link
                        to="/"
                        className="w-fit h-fit text-sm lg:text-2xl font-bold text-white flex flex-row items-center gap-3"
                    >
                        <img
                            src="/images/icon.png"
                            alt="profile"
                            width={80}
                            height={80}
                            placeholder="blur"
                            className="w-8 lg:w-10 h-auto mr-1"
                        />
                        Bet all Software & Sports Betting Accounts
                    </Link>

                    <div className="w-full hidden lg:flex items-center justify-end flex-1 list-none gap-1 text-xs ">
                        {navMenus.map((menu, index) => {
                            const depthLevel = 0;
                            return (
                                <MenuItems
                                    items={menu}
                                    key={index}
                                    mobileNav={mobileMenuVisible}
                                    depthLevel={depthLevel}
                                    handleCloseMobileMenu={() =>
                                        hideMobileMenu(menu)
                                    }
                                />
                            );
                        })}
                    </div>

                    <div className="lg:hidden flex justify-end">
                        <button
                            type="button"
                            name="menu-btn"
                            aria-label="menu button"
                            className="outline-none menu-button"
                            onClick={(_) => toggleMenu()}
                        >
                            {mobileMenuVisible ? (
                                <FontAwesomeIcon
                                    icon={faXmark}
                                    className="text-2xl xs:text-xl hover:scale-110 transition duration-300 ease-in-out"
                                />
                            ) : (
                                <FontAwesomeIcon
                                    icon={faBars}
                                    className="text-2xl xs:text-xl hover:scale-110 transition duration-300 ease-in-out"
                                />
                            )}
                        </button>
                    </div>
                </div>

                {/* Mobile Menu */}

                <div
                    className="mobile-menu text-center flex-col m-0 w-[50%] md:w-[25%] bg-[var(--dialogColor)] h-screen absolute top-full right-0 transition duration-300 ease-in-out drop_out hidden opacity-0 z-0 invisible nav_shadow lg:hidden"
                    ref={mobileMenuRef}
                >
                    <div className="flex flex-col list-none">
                        {navMenus.map((menu, index) => {
                            const depthLevel = 0;
                            return (
                                <MenuItems
                                    items={menu}
                                    key={index}
                                    mobileNav={mobileMenuVisible}
                                    depthLevel={depthLevel}
                                    handleCloseMobileMenu={() =>
                                        hideMobileMenu(menu)
                                    }
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NavBar;

// import React from "react";

// import { useRef, useState } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
// import useOnClickOutside from "../../hooks/useOnClickOutside";
// import navMenus from "../../data/navMenus";
// import MenuItems from "./MenuItems";
// import { Link, useNavigate } from "react-router-dom";
// import About from "../../data/about";

// const NavBar = () => {
//   return (
//     <div className="bg-white">
//       <div>
//         <img
//           src={About.avatarUrl}
//           alt="profile"
//           style={{
//             objectFit: "fill",
//             width: "100%",
//             height: "300px",
//           }}
//         />
//       </div>

//       <div className="flex justify-around items-center">
//         {navMenus.map((menu, index) => {
//           return (
//             <a className="text-black hover:bg-red-200 w-full h-full cursor-pointer p-10 text-2xl">
//               {menu.title}
//             </a>
//           );
//         })}
//       </div>

//       <div className="bg-[var(--dialogColor)] p-32 text-5xl">
//         Welcome to Bet All Software
//       </div>
//     </div>
//   );
// };

// export default NavBar;
