class Strings {
    static mail = "Mail";
    static mailLink = "mailto:australiabettingaccs@gmail.com";
    static mailUsername = "australiabettingaccs@gmail.com";

    static telegram = "Telegram";
    static telegramLink = "https://t.me/+C-kJJPftj_hhYTI1";
    static telegramUsername = "Betallsoftware";

    static facebook = "Facebook";
    static facebookLink =
        "https://www.facebook.com/profile.php?id=61554319537947";
    static facebookUsername = "Betallsoftware";

    static whatsApp = "whatsApp";
    static whatsAppLink = "https://wa.me/61466126140";
    static whatsAppUsername = "+61 466 126 140";

    static instagram = "Instagram";
    static instagramLink = "https://www.instagram.com/@betallsoftware";
    static instagramUsername = "Betallsoftware";

    static snapChat = "SnapChat";
    static snapChatLink = "SnapChat";
    static snapChatUsername = "Betsoftware";

    static fullName = "Bet all Software & Sports Betting Accounts";
    static shortName = "BetAll Sales";
}

export default Strings;
