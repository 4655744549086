const promotion_features = {
    bookieImages: [
        {
            url: "/images/promotion_img/sb.jpg",
            caption: "Sportsbet",
        },
        {
            url: "/images/promotion_img/neds.jpg",
            caption: "Neds",
        },
        {
            url: "/images/promotion_img/ladbrokes.jpg",
            caption: "Ladbrokes",
        },
        {
            url: "/images/promotion_img/tab.jpg",
            caption: "Tab",
        },
    ],
    windowImages: [
        {
            url: "/images/promotion_img/window_accounts.png",
            caption: "Login",
        },
        {
            url: "/images/promotion_img/windows_betting1.png",
            caption: "TokenPage1",
        },
        {
            url: "/images/promotion_img/windows_betting2.png",
            caption: "BettingPage1",
        },
        {
            url: "/images/promotion_img/windows_races1.png",
            caption: "BettingPage2",
        },
        {
            url: "/images/promotion_img/windows_races2.png",
            caption: "BettingPage3",
        },
    ],
    phoneImages: [
        {
            url: "/images/promotion_img/login_page1.jpg",
            caption: "Login",
        },
        {
            url: "/images/promotion_img/token_page1.jpg",
            caption: "TokenPage1",
        },
        {
            url: "/images/promotion_img/betting_page1.jpg",
            caption: "BettingPage1",
        },
        {
            url: "/images/promotion_img/betting_page2.jpg",
            caption: "BettingPage2",
        },
        {
            url: "/images/promotion_img/betting_page3.jpg",
            caption: "BettingPage3",
        },
        {
            url: "/images/promotion_img/betting_page4.jpg",
            caption: "BettingPage4",
        },
    ],
};

export default promotion_features;
