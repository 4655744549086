import React from "react";

import { useRef } from "react";
import ConstraintedBox from "../common/ConstraintedBox";
import ResponsiveBox from "../common/ResponsiveBox";
import features from "../../data/features";
import promotion_features from "../../data/promotion_features";

const Promotion_Section4 = () => {
    const featuresRef = useRef(null);

    return (
        <ResponsiveBox
            classNames="bg-[var(--bgColor)] min-h-[40vh] items-center justify-center"
            id="features"
            elementRef={featuresRef}
        >
            <ConstraintedBox classNames="p-4 py-12 sm:mt-10 items-center">
                <h1 className="text-center w-full pb-6 text-2xl sm:text-3xl">
                    WHY YOU NEED Bet All Softwares Promo Tool
                </h1>
                <div className="text-lg sm:text-xl w-4/5">
                    If your using multiple accounts multiple bookies you won't
                    want to make the bet across all your phones or all your
                    computers this takes to much time, this way you only need to
                    bet once. This allows you to bet from different IPS and
                    different GPS locations as long as your phones are using
                    different sim cards.
                </div>
            </ConstraintedBox>
        </ResponsiveBox>
    );
};

export default Promotion_Section4;
