import React from "react";
import { useNavigate } from "react-router-dom";

const AccountSection3 = () => {
    const navigate = useNavigate();
    const handleClick = () => {
        window.open(
            "https://www.facebook.com/profile.php?id=61554319537947",
            "_blank"
        );
    };
    return (
        <div className=" bg-[var(--bgColor)] font-poppins-semibold bg-cover text-black">
            <div className="sm:w-[730px] mx-auto w-full">
                <div className="pt-[60px] text-4xl text-white">
                    Our Referral Program
                </div>
                <div className="mt-[10px] text-xl text-gray-300 font-poppins">
                    (Invite mates - make $$$)
                </div>
                <div className="bg-white sm:rounded-s-full sm:rounded-e-full rounded-3xl border border-gray-500 sm:px-10 pt-3 pb-4 mx-auto mt-[50px] max-sm:mx-4">
                    <div className="flex">
                        <div className="sm:w-[200px] max-sm:ml-[20px] max-sm:mt-[30px]">
                            <div className="text-green-900 text-xl">$150</div>
                            <img
                                src="/images/accounts/cashout.gif"
                                alt="logo"
                                style={{
                                    objectFit: "cover",
                                    width: "100%",
                                }}
                            />
                        </div>
                        <div className=" sm:px-20 py-4 px-4 text-2xl font-poppins text-gray-500">
                            Introducing our Referral Program Your ticket to
                            earning{" "}
                            <span className="text-gray-600 font-semibold">
                                up to $150
                            </span>{" "}
                            for each successful referral!
                        </div>
                    </div>
                </div>
                <div className="grid sm:grid-cols-2 sm:gap-2 gap-4 mt-[50px] max-sm:mx-4">
                    <div>
                        <div className="z-0 relative max-sm:hidden">
                            <img
                                src="/images/accounts/cross.png"
                                alt="cross"
                                style={{
                                    objectFit: "cover",
                                    width: "165px",
                                }}
                                className="absolute -ml-4 -mt-4"
                            />
                        </div>
                        <div className="bg-white rounded-lg border border-gray-500 px-8 z-10 relative">
                            <div className="flex items-center py-2">
                                <div>
                                    <div className="bg-gray-200 rounded-full w-[80px] h-[80px]">
                                        <img
                                            src="/images/accounts/speaker.png"
                                            alt="speaker"
                                            style={{
                                                objectFit: "cover",
                                                width: "60px",
                                            }}
                                            className="-rotate-6 m-auto p-1 pt-2"
                                        />
                                    </div>
                                </div>
                                <div className="text-xl text-gray-900 mx-auto px-10">
                                    1. Spread the Word
                                </div>
                            </div>
                            <div className="py-5 font-poppins text-gray-500 text-lg">
                                Tell your friends and family they can make $200
                                cash easily by signing up with BetAllSoftware.
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="bg-white rounded-lg border border-gray-500 px-6 z-10 relative">
                            <div className="flex items-center py-2 px-2">
                                <div>
                                    <div className="bg-gray-200 rounded-full w-[80px] h-[80px]">
                                        <img
                                            src="/images/accounts/money-bag.png"
                                            alt="speaker"
                                            style={{
                                                objectFit: "cover",
                                                width: "60px",
                                            }}
                                            className="m-auto pt-2"
                                        />
                                    </div>
                                </div>
                                <div className="text-xl text-gray-900 mx-auto px-10">
                                    2. Cash In!
                                </div>
                            </div>
                            <div className="py-5 font-poppins text-gray-500 text-lg">
                                Once their application is approved, we'll send
                                you up to $150 for each person you referred.
                            </div>
                        </div>
                        <img
                            src="/images/accounts/cross.png"
                            alt="cross"
                            style={{
                                objectFit: "cover",
                                width: "165px",
                            }}
                            className="absolute -mt-[150px] ml-[212px] max-sm:hidden"
                        />
                    </div>
                </div>
                <div className="text-xl text-gray-300 font-poppins mt-[80px] max-sm:px-10">
                    Questions about the referral program or want to get started?
                </div>
                <div className="mt-[30px] font-poppins-regular">
                    <div
                        className="text-white text-lg bg-purple-600 rounded-3xl w-[280px] h-[55px] tracking-widest border-2 border-transparent hover:border-purple-600 hover:bg-white hover:text-purple-600 duration-200 cursor-pointer p-3 mx-auto"
                        onClick={handleClick}
                    >
                        MESSAGE US NOW
                    </div>
                </div>
                <div className="pt-[50px]"></div>
            </div>
        </div>
    );
};

export default AccountSection3;
