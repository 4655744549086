import React from "react";

import { useRef } from "react";
import ConstraintedBox from "../common/ConstraintedBox";
import ResponsiveBox from "../common/ResponsiveBox";
import features from "../../data/features";
import promotion_features from "../../data/promotion_features";

const Promotion_Section3 = () => {
    const featuresRef = useRef(null);

    return (
        <ResponsiveBox
            classNames="bg-[var(--bgColor)] min-h-[40vh] items-center justify-center"
            id="features"
            elementRef={featuresRef}
        >
            <ConstraintedBox classNames="p-4 py-12 sm:mt-10 items-center">
                <h1 className="text-center w-full">HOW IT WORKS</h1>
                <div className="text-center w-full text-2xl text-green-400 pt-4">
                    Bet across multiple bookmakers multiple accounts multiple
                    phones with one click
                </div>
                <div className="grid sm:grid-cols-2 justify-between w-2/3 mt-10 gap-16">
                    <div className="text-left text-xl">
                        <span className="font-medium">1. Connect Accounts</span>
                        <p className="mt-2">
                            Add your accounts on the phone and computer app.
                            Your account information is only stored on your
                            computer for the bot and NEVER sent to us.
                        </p>
                    </div>
                    <div className="text-left text-xl">
                        <span className="font-medium">
                            2. Make the bet (normal bet) (bet return) (bonus
                            bet)
                        </span>
                        <p className="mt-2">
                            Once your bet is made click start it'll send to all
                            your phones connected all your accounts
                        </p>
                    </div>
                </div>
            </ConstraintedBox>
        </ResponsiveBox>
    );
};

export default Promotion_Section3;
