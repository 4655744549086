import axios from "axios";

// Create an instance of axios
const api = axios.create({
    baseURL: "https://api.betallsoftware.com/api",
    headers: {
        "Content-Type": "application/json",
    },
});

export default api;
