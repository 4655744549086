import React, { useEffect, useState } from "react";
import ResponsiveBox from "../common/ResponsiveBox";
import ConstraintedBox from "../common/ConstraintedBox";
import { getStores } from "../../api/crud";
import { RatingSet } from "./RatingSet";
import { RatingView } from "./RatingView";

export const Ratings = () => {
    const [reviews, setReviews] = useState(null);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        const getReviewsInfo = async () => {
            const reviewInfos = await getStores("reviews");
            if (reviewInfos === null) return;
            const items = Object.entries(reviewInfos).map((item, index) => {
                let data = {
                    id: item[1].id,
                    writer: item[1].writer,
                    selfie: item[1].selfie,
                    value: item[1].value,
                    content: item[1].content,
                };
                return data;
            });
            const sortedItems = items.sort((a, b) => b.id - a.id);
            setReviews(sortedItems);
        };

        getReviewsInfo();
    }, [refresh]);

    return (
        <ResponsiveBox classNames="bg-[var(--dialogColor)] min-h-[100vh]">
            <ConstraintedBox classNames="p-4 py-12">
                <RatingSet refresh={refresh} setRefresh={setRefresh} />
                <RatingView reviews={reviews} />
            </ConstraintedBox>
        </ResponsiveBox>
    );
};
