import React, { useState } from "react";
import PageBox from "../../components/common/PageBox";
import NavBar from "../../components/navbar/NavBar";
import AccountForm from "../../components/home/AccountForm";
import ReactPlayer from "react-player";
import ConstraintedBox from "../../components/common/ConstraintedBox";
import ResponsiveBox from "../../components/common/ResponsiveBox";
import PrivacyForm from "../../components/home/PrivacyPolicy";
import TermsForm from "../../components/home/TermsAndConditions";

export default function AccountFormPage() {
  const [formIndex, setFormIndex] = useState(1);
  return (
    <>
      <NavBar />
      <PageBox>
        <div className="mx-auto sm:mt-10 mb-6 p-2 sm:w-[600px] max-sm:-translate-x-[120px]">
          <ReactPlayer
            controls={true}
            playing={false}
            url={"https://www.youtube.com/watch?v=lu-KfLXiO_8"}
          />
        </div>
        <ResponsiveBox classNames="bg-[var(--dialogColor)] min-h-[100vh]">
          <ConstraintedBox classNames="p-4 py-12">
            <div className="mx-auto md:w-3/4 lg:2/3 border border-gray-600 rounded-md shadow-sm shadow-gray-200 bg-white text-black p-4">
              <div className="w-full flex flex-grow m-0 text-center lg:text-lg text-base">
                <div
                  className="p-4 border border-gray-600 bg-gray-300 w-full cursor-pointer hover:bg-gray-200"
                  onClick={() => setFormIndex(3)}
                >
                  Terms And Conditions
                </div>
                <div
                  className="p-4 border border-gray-600 bg-gray-300 w-full cursor-pointer hover:bg-gray-200"
                  onClick={() => setFormIndex(2)}
                >
                  Privacy Policy
                </div>
                <div
                  className="p-4 border border-gray-600 bg-gray-300 w-full cursor-pointer hover:bg-gray-200"
                  onClick={() => setFormIndex(1)}
                >
                  Customer Form
                </div>
              </div>
              {formIndex === 1 ? (
                <AccountForm />
              ) : formIndex === 2 ? (
                <PrivacyForm />
              ) : (
                <TermsForm />
              )}
            </div>
          </ConstraintedBox>
        </ResponsiveBox>
      </PageBox>
      {/* <Footer bgType={1} /> */}
    </>
  );
}
