import React from "react";

import { useRef } from "react";
import ConstraintedBox from "../common/ConstraintedBox";
import ResponsiveBox from "../common/ResponsiveBox";

const HomeSection6 = () => {
    const featuresRef = useRef(null);

    return (
        <ResponsiveBox
            classNames="bg-[var(--bgColor)] min-h-[90vh] items-center justify-center"
            id="features"
            elementRef={featuresRef}
        >
            <ConstraintedBox classNames="p-4 py-12 sm:mt-10">
                <div className="relative w-full grid grid-cols-1 transition duration-300 ease-in-out justify-items-center mt-12">
                    <div className="slide-container">
                        <div>
                            <img
                                src="/images/signupbot.png"
                                alt="profile"
                                width={1000}
                                height={100}
                            />
                        </div>
                        {/* </Slide> */}
                    </div>
                </div>
                <div className="text-center w-full">
                    <div className="mt-[80px] font-bold text-4xl mb-[20px]">
                        Verified Sellers of Account Creator Bot
                    </div>
                    <a
                        className="text-xl font-bold block"
                        href="https://t.me/@vCR45H"
                    >
                        (Telegram @vCR45H)
                    </a>
                    <a
                        className="text-xl font-bold"
                        href="https://t.me/@vCR45H"
                    >
                        (Telegram @Betallsoftware)
                    </a>
                </div>
            </ConstraintedBox>
        </ResponsiveBox>
    );
};

export default HomeSection6;
