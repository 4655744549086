import React, { useState } from "react";
import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxOption,
    ComboboxOptions,
    Transition,
} from "@headlessui/react";
import clsx from "clsx";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";

const ComboBox = ({ selected, setSelected, data }) => {
    const [query, setQuery] = useState("");

    const filtered =
        query === ""
            ? data
            : data.filter((item) => {
                  return item.value.toLowerCase().includes(query.toLowerCase());
              });
    return (
        <Combobox value={selected} onChange={(value) => setSelected(value)}>
            <div className="relative">
                <ComboboxInput
                    className={clsx(
                        "w-full rounded-lg border-none bg-white/5 py-1.5 pr-8 pl-3 text-sm/6 text-white",
                        "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                    )}
                    displayValue={(item) => item?.value}
                    onChange={(event) => setQuery(event.target.value)}
                />
                <ComboboxButton className="group absolute inset-y-0 right-0 p-2.5">
                    <ChevronDownIcon className="size-4 fill-white/60 group-data-[hover]:fill-white" />
                </ComboboxButton>
            </div>
            <Transition
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setQuery("")}
            >
                <ComboboxOptions
                    anchor="bottom"
                    className="w-[var(--input-width)] rounded-xl border border-white/5 bg-gray-900/90 p-1 [--anchor-gap:var(--spacing-1)] empty:hidden"
                >
                    {filtered.map((item) => (
                        <ComboboxOption
                            key={item.id}
                            value={item}
                            className="group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-white/10"
                        >
                            <CheckIcon className="invisible size-4 fill-white group-data-[selected]:visible" />
                            <div className="text-sm/6 text-white">
                                {item.value}
                            </div>
                        </ComboboxOption>
                    ))}
                </ComboboxOptions>
            </Transition>
        </Combobox>
    );
};

export default ComboBox;
