import { Box, Rating, Typography } from "@mui/material";
import React, { useState } from "react";
import InputBox from "../common/InputBox";
import DragDropFile from "../common/DragDropFile";
import { saveStore } from "../../api/crud";
import { toast } from "react-toastify";

export const RatingSet = ({ refresh, setRefresh }) => {
    const [value, setValue] = useState(0);
    const [writer, setWriter] = useState("");
    const [content, setContent] = useState("");
    const [selfie, setSelfie] = useState("");

    const onSubmit = (e) => {
        e.preventDefault();
        const data = {
            writer,
            content,
            value,
            selfie,
        };
        saveStore("reviews", data);
        setRefresh(!refresh);
        setValue(0);
        setWriter("");
        setContent("");
        setSelfie("");
        toast("Submit successful!");
    };

    return (
        <div className="text-left mx-auto text-xl sm:text-3xl p-4 font-medium sm:w-3/4 border border-gray-600 rounded-md shadow-sm shadow-white bg-gray-100 text-black">
            <h2>You can leave reviews here</h2>
            <form id="review_form" onSubmit={onSubmit}>
                <div>
                    <p className="mt-4">
                        Full Name:
                        <span className="text-red-500 pl-1">*</span>
                    </p>
                    <InputBox
                        type="text"
                        name="txt_email"
                        value={writer}
                        setValue={setWriter}
                        classNames="text-black p-2 text-sm w-full sm:w-1/2 border border-gray-500"
                        required
                    />
                    <p className="mt-4 mb-2">
                        Please upload your selfie photo
                        <span className="text-red-500 pl-1">*</span>
                    </p>
                    <DragDropFile
                        imgUrl={selfie}
                        setImgUrl={setSelfie}
                        folder={"ratings"}
                    />
                    <p className="mt-4">
                        Please write review:
                        <span className="text-red-500 pl-1">*</span>
                    </p>
                    <textarea
                        name="txt_review"
                        value={content}
                        rows={5}
                        onChange={(e) => {
                            setContent(e.target.value);
                        }}
                        required
                        placeholder="You can leave reviews about this website"
                        className="text-black p-2 text-sm w-full border border-gray-500"
                    />
                </div>
                <Box
                    sx={{
                        "& > legend": { mt: 2 },
                    }}
                >
                    <Typography component="legend">Ratings</Typography>
                    <Rating
                        name="simple-controlled"
                        value={value}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                        }}
                    />
                    {/* <Typography component="legend">Read only</Typography>
                        <Rating name="read-only" value={value} readOnly />
                        <Typography component="legend">Disabled</Typography>
                        <Rating name="disabled" value={value} disabled />
                        <Typography component="legend">
                            No rating given
                        </Typography>
                        <Rating name="no-value" value={null} /> */}
                </Box>
                <button
                    type="submit"
                    className="text-white px-6 py-2 bg-gray-700 hover:bg-gray-500 text-base border border-white shadow-sm shadow-black rounded-md float-right"
                >
                    Submit
                </button>
            </form>
        </div>
    );
};
