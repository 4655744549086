import React, { useEffect, useRef, useState } from "react";
import ResponsiveBox from "../common/ResponsiveBox";
import ConstraintedBox from "../common/ConstraintedBox";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import clsx from "clsx";
import api from "../../utils/api";
import ComboBox from "../common/ComboBox";
import { getWeekStartDate, getWeekday } from "../../utils/utils";
import { Button, Field, Input, Label } from "@headlessui/react";

const monthList = [
    { id: 0, value: "All" },
    { id: 1, value: "Jan" },
    { id: 2, value: "Feb" },
    { id: 3, value: "Mar" },
    { id: 4, value: "Apr" },
    { id: 5, value: "May" },
    { id: 6, value: "Jun" },
    { id: 7, value: "Jul" },
    { id: 8, value: "Aug" },
    { id: 9, value: "Sep" },
    { id: 10, value: "Oct" },
    { id: 11, value: "Nov" },
    { id: 12, value: "Dec" },
];

const betStyleList = [
    { id: 0, value: "All" },
    { id: 1, value: "Success Bets" },
    { id: 2, value: "Failure Bets" },
];

const siteTypeList = [
    { id: 0, value: "All" },
    { id: 1, value: "Sportsbet" },
    { id: 2, value: "Ladbrokes" },
];

const daysList = [
    { id: 0, value: "Today" },
    { id: 1, value: "All" },
    { id: 2, value: "Mon" },
    { id: 3, value: "Tue" },
    { id: 4, value: "Wed" },
    { id: 5, value: "Thu" },
    { id: 6, value: "Fri" },
    { id: 7, value: "Sat" },
    { id: 8, value: "Sun" },
];

const betTypeList = [
    { id: 0, value: "All" },
    { id: 1, value: "Regular Bet" },
    { id: 2, value: "Field Bet" },
];

const raceTypeList = [
    { id: 0, value: "All" },
    { id: 1, value: "Horse Racing" },
    { id: 2, value: "Harness Racing" },
];

let emailList = [{ id: 0, value: "All" }];

let raceList = [{ id: 0, value: "All" }];

const BettingHistory = () => {
    const [orgData, setOrgData] = useState([]);
    const [rowData, setRowData] = useState([]);
    const [month, setMonth] = useState(monthList[0]);
    const [siteType, setSiteType] = useState(siteTypeList[0]);
    const [betStyle, setBetStyle] = useState(betStyleList[0]);
    const [day, setDay] = useState(daysList[1]);
    const [betType, setBetType] = useState(betTypeList[0]);
    const [raceType, setRaceType] = useState(raceTypeList[0]);
    const [raceName, setRaceName] = useState(raceList[0]);
    const [email, setEmail] = useState(emailList[0]);
    const [EV, setEV] = useState("");

    const [totalEarnings, setTotalEarnings] = useState("0");
    const [totalLost, setTotalLost] = useState("0");
    const [totalProfit, setTotalProfit] = useState("0");
    const [winningRate, setWinningRate] = useState(0);

    const [dailyProfit, setDailyProfit] = useState("");
    const [weeklyProfit, setWeeklyProfit] = useState("");
    const [monthlyProfit, setMonthlyProfit] = useState("");
    const [yearlyProfit, setYearlyProfit] = useState("");

    const inputRef = useRef(null);

    const autoSizeStrategy = {
        type: "fitGridWidth",
        defaultMinWidth: 50,
        columnLimits: [
            {
                colId: "no",
                minWidth: 50,
            },
            {
                colId: "name",
                minWidth: 40,
            },
            {
                colId: "betInfo",
                minWidth: 300,
            },
        ],
    };

    // Column Definitions: Defines & controls grid columns.
    const [colDefs, setColDefs] = useState([
        { field: "no" },
        { field: "siteType", hide: true },
        { field: "email" },
        { field: "name" },
        { field: "date" },
        { field: "status" },
        { field: "betInfo" },
        { field: "profit" },
    ]);

    useEffect(() => {
        const getSalesInfo = async () => {
            const history = await api.get("/betting_history?userId=desktop");
            let items = history?.data.map((item, index) => {
                let content = item.content;
                let splits = content.split("!");
                let data = {
                    no: index + 1,
                    siteType: splits[0],
                    email: splits[1],
                    name: splits[2],
                    date: splits[3],
                    status: splits[4],
                    betInfo: splits[5],
                    profit: splits[6],
                };
                let tmp1 = data.betInfo.split(",");
                let raceInfo = tmp1[0];
                let tmp2 = raceInfo.split(" ");
                let str = "";
                for (let i = 1; i < tmp2.length; i++) {
                    if (str === "") {
                        str += tmp2[i];
                    } else {
                        str += ` ${tmp2[i]}`;
                    }
                }
                let raceItem = {
                    id: raceList.length,
                    value: str,
                };
                if (
                    !raceList.find(
                        (element) => element.value === raceItem.value
                    )
                )
                    raceList = [...raceList, raceItem];

                let emailItem = {
                    id: emailList.length,
                    value: data.email,
                };
                if (!emailList.find((one) => one.value === emailItem.value))
                    emailList = [...emailList, emailItem];

                return data;
            });
            items.sort((a, b) => {
                let dateA = new Date(`2024/${a.date}`);
                let dateB = new Date(`2024/${b.date}`);
                return dateA - dateB;
            });
            setOrgData(items);
            setRowData(items);
        };

        getSalesInfo();
    }, []);

    useEffect(() => {
        let curDate = new Date();
        let formattedDate = curDate.toLocaleDateString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
        });
        let dateAndMonth = formattedDate.substring(0, 5);
        let startDate = "";
        let endDate = "";
        let dates = 0;
        if (orgData) {
            const items = orgData.filter((item, index) => {
                let flag1 = true;
                let flag2 = true;
                let flag3 = true;
                let flag4 = true;
                let flag5 = true;
                let flag6 = true;
                let flag7 = true;
                let flag8 = true;
                let flag9 = true;
                let canParse = !isNaN(parseFloat(EV));
                let sMonth = item.date.substring(0, 5).split("/")[0];
                let iMonth = 0;

                if (sMonth.startsWith("0")) {
                    iMonth = parseInt(sMonth.substring(1));
                } else {
                    iMonth = parseInt(sMonth);
                }
                if (siteType.value === "Sportsbet") {
                    flag1 = item.siteType === "sb";
                } else if (siteType.value === "Ladbrokes") {
                    flag1 = item.siteType === "lb";
                }

                if (betStyle.value === "Failure Bets") {
                    flag2 = item.status.includes("(F)");
                } else if (betStyle.value === "Success Bets") {
                    flag2 = !item.status.includes("(F)");
                }

                if (betType.value === "Regular Bet") {
                    flag3 = !(
                        item.betInfo.includes("0,") ||
                        item.betInfo.includes("All other runners")
                    );
                } else if (betType.value === "Field Bet") {
                    flag3 =
                        item.betInfo.includes("0,") ||
                        item.betInfo.includes("All other runners");
                }

                if (raceType.value === "Horse Racing") {
                    flag4 = item.betInfo.includes("[horse]");
                } else if (raceType.value === "Harness Racing") {
                    flag4 = item.betInfo.includes("[harness]");
                }

                if (month.id !== 0 && month.id !== iMonth) {
                    flag5 = false;
                }

                if (day.value === "Today") {
                    flag6 = item.date.substring(0, 5) === dateAndMonth;
                } else if (day.value !== "All") {
                    flag6 = getWeekday(item.date) === day.value;
                }

                if (raceName.value !== "All")
                    flag7 = item.betInfo.includes(raceName.value);

                if (email.value !== "All") flag8 = item.email === email.value;

                if (canParse) {
                    if (!item.betInfo.includes("|")) flag9 = false;
                    let pos1 = item.betInfo.indexOf("@") + 1;
                    let pos2 = item.betInfo.indexOf("Stake");
                    let pos3 = item.betInfo.indexOf("|");
                    let price1 = item.betInfo.substring(pos1, pos3);
                    let price2 = item.betInfo.substring(pos3 + 1, pos2).trim();
                    let calculatedEV =
                        (parseFloat(price1) / parseFloat(price2) - 1) * 100;
                    if (calculatedEV < EV) flag9 = false;
                }

                return (
                    flag1 &&
                    flag2 &&
                    flag3 &&
                    flag4 &&
                    flag5 &&
                    flag6 &&
                    flag7 &&
                    flag8 &&
                    flag9
                );
            });
            let tmp1 = 0;
            let tmp2 = 0;
            let tmp3 = 0;
            let tmp4 = 0;
            let winCount = 0;
            const processedItems = items.map((item, index) => {
                if (startDate === "") {
                    startDate = item.date.substring(0, 5);
                }

                if (endDate !== item.date.substring(0, 5)) {
                    dates++;
                }
                endDate = item.date.substring(0, 5);

                if (item.status === "Win" || item.status === "Win(F)") {
                    tmp1 += parseFloat(item.profit);
                    winCount++;
                } else {
                    tmp2 += parseFloat(item.profit);
                }
                const newItem = {
                    ...item,
                    profit: parseFloat(item.profit).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                    }),
                    no: index + 1,
                };
                return newItem;
            });
            tmp1 = Math.round(tmp1 * 100) / 100;
            tmp2 = Math.round(Math.abs(tmp2) * 100) / 100;
            tmp3 = Math.round((tmp1 - tmp2) * 100) / 100;
            tmp4 = Math.round((winCount / processedItems.length) * 100) / 100;
            setTotalEarnings(
                tmp1.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                })
            );
            setTotalLost(
                tmp2.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                })
            );
            setTotalProfit(
                tmp3.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                })
            );

            let currentYear = new Date().getFullYear(); // Get the current year

            let startDateString = startDate + "/" + currentYear;
            let endDateString = endDate + "/" + currentYear;

            let date1 = new Date(startDateString);
            let date2 = new Date(endDateString);

            let weekStart = getWeekStartDate(date1);

            let weeks = 0;
            while (weekStart <= date2) {
                weeks++;
                weekStart.setDate(weekStart.getDate() + 7);
            }

            let monthDue = date2.getMonth() - date1.getMonth() + 1;

            setDailyProfit(
                (tmp3 / dates).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                })
            );
            setWeeklyProfit(
                (tmp3 / weeks).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                })
            );
            setMonthlyProfit(
                (tmp3 / monthDue).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                })
            );
            setYearlyProfit(
                tmp3.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                })
            );

            setWinningRate(tmp4);
            setRowData(processedItems);
        }
    }, [
        month,
        betStyle,
        siteType,
        day,
        betType,
        raceType,
        raceName,
        email,
        EV,
    ]);

    const handleFilter = () => {
        setEV(inputRef.current.value);
    };

    return (
        <ResponsiveBox classNames="bg-[var(--bgColor)] min-h-[100vh] items-center justify-center">
            <ConstraintedBox classNames="p-4 py-12">
                <div className="text-center mx-auto mb-10 text-xl sm:text-3xl font-medium">
                    Live Racing Bot Results
                </div>
                <div className="px-4 flex-row max-sm:space-y-2 sm:flex mx-auto sm:space-x-4">
                    <ComboBox
                        selected={siteType}
                        setSelected={setSiteType}
                        data={siteTypeList}
                    />
                    <ComboBox
                        selected={email}
                        setSelected={setEmail}
                        data={emailList}
                    />
                    <ComboBox
                        selected={betStyle}
                        setSelected={setBetStyle}
                        data={betStyleList}
                    />
                    <Field className="flex items-center space-x-1">
                        <Label className="text-sm/6 font-medium text-white">
                            EV:
                        </Label>
                        <Input
                            className={clsx(
                                "block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white",
                                "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                            )}
                            ref={inputRef}
                        />
                        <Button
                            className="inline-flex items-center gap-2 rounded-md bg-gray-700 py-1.5 px-6 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white"
                            onClick={handleFilter}
                        >
                            Filter
                        </Button>
                    </Field>
                </div>
                <div className="p-4 sm:flex max-sm:space-y-2 mx-auto sm:space-x-4 w-3/4">
                    <ComboBox
                        selected={month}
                        setSelected={setMonth}
                        data={monthList}
                    />
                    <ComboBox
                        selected={day}
                        setSelected={setDay}
                        data={daysList}
                    />
                    <ComboBox
                        selected={betType}
                        setSelected={setBetType}
                        data={betTypeList}
                    />
                    <ComboBox
                        selected={raceType}
                        setSelected={setRaceType}
                        data={raceTypeList}
                    />
                    <ComboBox
                        selected={raceName}
                        setSelected={setRaceName}
                        data={raceList}
                    />
                </div>
                <div
                    className={clsx("ag-theme-quartz-dark", "m-auto")}
                    style={{ width: "80%", height: "60vh" }}
                >
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={colDefs}
                        autoSizeStrategy={autoSizeStrategy}
                    />
                </div>
                <div className="flex mx-auto justify-around sm:w-3/4 mt-4 text-lg max-sm:block max-sm:text-base">
                    <div className="flex space-x-2">
                        <span>Total Earnings:</span>
                        <span>{totalEarnings}</span>
                    </div>
                    <div className="flex space-x-2">
                        <span>Total Lost:</span>
                        <span>{totalLost}</span>
                    </div>
                    <div className="flex space-x-2">
                        <span>Profit:</span>
                        <span>{totalProfit}</span>
                    </div>
                    <div className="flex space-x-2">
                        <span>Winning Rate:</span>
                        <span>{winningRate}</span>
                    </div>
                </div>
                <div className="flex mx-auto justify-around sm:w-3/4 mt-2 text-lg max-sm:block max-sm:text-base">
                    <div className="flex space-x-2">
                        <span>Daily Profit:</span>
                        <span>{dailyProfit}</span>
                    </div>
                    <div className="flex space-x-2">
                        <span>Weekly Profit:</span>
                        <span>{weeklyProfit}</span>
                    </div>
                    <div className="flex space-x-2">
                        <span>Monthly Profit:</span>
                        <span>{monthlyProfit}</span>
                    </div>
                    <div className="flex space-x-2">
                        <span>Yearly Profit:</span>
                        <span>{yearlyProfit}</span>
                    </div>
                </div>
            </ConstraintedBox>
        </ResponsiveBox>
    );
};

export default BettingHistory;
