import React from "react";
import PageBox from "../../components/common/PageBox";
import Footer from "../../components/home/Footer";
import NavBar from "../../components/navbar/NavBar";
import HomeSection6 from "../../components/home/Section6";
import VideoSection from "../../components/home/VideoSection";
import HomeSection8 from "../../components/home/Section8";

export default function SignUpBotPage() {
    return (
        <>
            <NavBar />
            <PageBox>
                <HomeSection6 />
                <VideoSection
                    url={"https://youtu.be/W4ga9gjz7VE"}
                    width={800}
                    height={600}
                />
                <HomeSection8 />
                {/* <Footer bgType={0} /> */}
            </PageBox>
        </>
    );
}
