import { ref } from "firebase/storage";
import {
    deleteObject,
    getDownloadURL,
    getStorage,
    uploadBytesResumable,
} from "firebase/storage";
import React, { useRef, useState } from "react";
import { updateStore } from "../../api/crud";

const DragDropFile = ({ imgUrl, setImgUrl, folder }) => {
    const [dragActive, setDragActive] = useState(false);
    // ref
    const inputRef = useRef(null);
    const [imgStatus, setImgStatus] = useState("");

    async function saveFileData(downloadURL, fileName) {
        setImgStatus("Upload Finished!");

        setImgUrl(downloadURL);
        setImgStatus(fileName);
    }

    async function handleUpload(storageRef, file) {
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const progress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setImgStatus("Upload is " + progress + "% done");
                switch (snapshot.state) {
                    case "paused":
                        setImgStatus("Upload is paused");
                        break;
                    // case "running":
                    //   setStatus(stat, "Upload is running");
                    //   break;
                    default:
                        break;
                }
            },
            (error) => {},
            () => {
                getDownloadURL(uploadTask.snapshot.ref)
                    .then((downloadURL) => {
                        saveFileData(downloadURL, file.name);
                    })
                    .catch((error) => console.log(error));
            }
        );
    }

    async function confirmUpload(files) {
        if (!files[0]) return;
        const storage = getStorage();
        const file = files[0];
        const uniqueId = new Date().getTime().toString();
        const storageRef = ref(storage, folder + "/" + uniqueId);

        try {
            await deleteObject(storageRef);
        } catch (e) {}
        await handleUpload(storageRef, file);
    }

    const handleDeleteImg = async (e) => {
        e.preventDefault();
        const storage = getStorage();
        const storageRef = ref(storage, imgUrl);
        setImgStatus("File Deleting!");
        try {
            await deleteObject(storageRef);
            setImgUrl("");
            setImgStatus("Deleted!");
        } catch (e) {}
    };

    // handle drag events
    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    // triggers when file is dropped
    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            confirmUpload(e.dataTransfer.files);
        }
    };

    // triggers when file is selected with click
    const handleChange = function (e) {
        e.preventDefault();
        console.log("here");
        if (e.target.files && e.target.files[0]) {
            confirmUpload(e.target.files);
        }
    };

    // triggers the input when the button is clicked
    const onButtonClick = (e) => {
        e.preventDefault();
        console.log(inputRef);
        inputRef.current.click();
    };

    return (
        <>
            <form
                className="relative w-full h-full text-center"
                onDragEnter={handleDrag}
                onSubmit={(e) => e.preventDefault()}
            >
                <input
                    ref={inputRef}
                    type="file"
                    className="sr-only"
                    id="input-file-upload"
                    multiple={true}
                    onChange={handleChange}
                />
                <label
                    className={
                        dragActive
                            ? "justify-center w-full h-full flex px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md gap-4 bg-gray-400"
                            : "justify-center w-full h-full flex px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md gap-4 bg-gray-200"
                    }
                    // htmlFor="input-file-upload"
                >
                    <div>
                        <div className="space-y-1 text-center">
                            <svg
                                className="mx-auto h-12 w-12 text-gray-400"
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 48 48"
                                aria-hidden="true"
                            >
                                <path
                                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <div className="flex text-sm text-gray-600">
                                <button
                                    className={
                                        dragActive
                                            ? "relative cursor-pointer bg-gray-400 rounded-md text-dark hover:text-lightDark focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-dark font-semibold text-indigo-600"
                                            : "relative cursor-pointer bg-gray-200 rounded-md text-dark hover:text-lightDark focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-dark font-semibold text-indigo-600"
                                    }
                                    onClick={onButtonClick}
                                >
                                    Upload a file
                                </button>
                                <p className="pl-1">or drag and drop</p>
                            </div>
                            <p className="text-xs text-gray-500">
                                PNG, JPG, GIF up to 10MB
                            </p>
                        </div>
                    </div>
                </label>
                {dragActive && (
                    <div
                        className="absolute w-full h-full top-0 right-0 bottom-0 left-0"
                        onDragEnter={handleDrag}
                        onDragLeave={handleDrag}
                        onDragOver={handleDrag}
                        onDrop={handleDrop}
                    ></div>
                )}
            </form>
            <span className="flex">
                <div className="p-3 text-black text-base">{imgStatus}</div>
                {imgUrl === "" || imgUrl === null ? null : (
                    <button
                        className="px-4 py-1 m-2 float-right text-base rounded-md shadow-sm bg-red-500 hover:bg-red-300"
                        onClick={handleDeleteImg}
                    >
                        Delete
                    </button>
                )}
            </span>
        </>
    );
};

export default DragDropFile;
