import React from "react";
import { useNavigate } from "react-router-dom";

const AccountSection2 = () => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate("/account_form", { replace: true });
    };
    return (
        <div className=" bg-stadium-img font-poppins-semibold bg-cover text-black">
            <div className="sm:w-[1160px] mx-auto w-full">
                <div className="pt-[60px] text-4xl text-white">
                    How It Works
                </div>
                <div className="mt-[10px] text-xl text-gray-300 font-poppins">
                    (3 simple steps)
                </div>
                <div className="grid sm:grid-cols-3 mt-[100px] sm:gap-x-4 max-sm:gap-y-14 max-sm:px-6">
                    <div className="pt-[20px] bg-amber-50 rounded-3xl px-4 font-poppins relative">
                        <div className="text-center flex items-center justify-center absolute -top-[60px] left-[115px]">
                            <svg
                                height="140"
                                width="140"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle
                                    r="45"
                                    cx="70"
                                    cy="70"
                                    fill="white"
                                    stroke="#777777"
                                    stroke-width="2"
                                />
                            </svg>
                            <span className="absolute font-poppins text-5xl">
                                1
                            </span>
                        </div>
                        <div className="relative text-lg mt-[60px]">
                            <div className="text-2xl font-poppins-semibold">
                                We Create Your Accounts
                            </div>
                            <div className="mt-[16px] text-gray-800">
                                Register by filling our form
                            </div>
                            <div className="text-gray-800">
                                (valid ID is required).
                            </div>
                            <div className="text-gray-800 mt-[12px] whitespace-pre-wrap mb-[20px]">
                                We'll then create your betting accounts if you
                                haven't done so already
                            </div>
                        </div>
                    </div>
                    <div className="pt-[20px] bg-amber-50 rounded-3xl px-4 font-poppins relative">
                        <div className="text-center flex items-center justify-center absolute -top-[60px] left-[115px]">
                            <svg
                                height="140"
                                width="140"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle
                                    r="45"
                                    cx="70"
                                    cy="70"
                                    fill="white"
                                    stroke="#777777"
                                    stroke-width="2"
                                />
                            </svg>
                            <span className="absolute font-poppins text-5xl">
                                2
                            </span>
                        </div>
                        <div className="relative text-lg mt-[60px]">
                            <div className="text-2xl font-poppins-semibold">
                                We Buy Your Accounts
                            </div>
                            <div className="mt-[16px] text-gray-800">
                                Once created, we purchase the betting accounts
                                for $200 total.
                            </div>
                            <div className="text-gray-800 mt-[12px] whitespace-pre-wrap mb-[20px]">
                                No active involvement needed.
                            </div>
                        </div>
                    </div>
                    <div className="pt-[20px] bg-amber-50 rounded-3xl px-4 font-poppins relative">
                        <div className="text-center flex items-center justify-center absolute -top-[60px] left-[115px]">
                            <svg
                                height="140"
                                width="140"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle
                                    r="45"
                                    cx="70"
                                    cy="70"
                                    fill="white"
                                    stroke="#777777"
                                    stroke-width="2"
                                />
                            </svg>
                            <span className="absolute font-poppins text-5xl">
                                3
                            </span>
                        </div>
                        <div className="relative text-lg mt-[60px]">
                            <div className="text-2xl font-poppins-semibold">
                                You Get Paid! 💸
                            </div>
                            <div className="mt-[16px] text-gray-800 max-sm:pb-[16px]">
                                As soon as we verify the betting accounts and UP
                                account you'll be paid instantly. <br />
                                (Usually this will be checked same day)
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-[80px] font-poppins-regular">
                    <div
                        className="text-gray-800 text-lg bg-green-500 rounded-3xl w-[280px] h-[55px] tracking-widest border-2 border-transparent hover:border-white shadow-white hover:bg-black hover:text-gray-200 duration-200 cursor-pointer p-3 mx-auto"
                        onClick={handleClick}
                    >
                        GET STARTED NOW
                    </div>
                </div>
                <div className="pt-[80px]"></div>
            </div>
        </div>
    );
};

export default AccountSection2;
