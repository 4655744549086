import { atom, selector } from "recoil";

export const atom_card1 = atom({
    key: "atom_card1",
    default: "",
});

export const atom_card2 = atom({
    key: "atom_card2",
    default: "",
});

export const atom_card3 = atom({
    key: "atom_card3",
    default: "",
});

export const atom_card4 = atom({
    key: "atom_card4",
    default: "",
});

export const signatureImg = atom({
    key: "signatureImg",
    default: "",
});
