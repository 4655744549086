import React from "react";
import ResponsiveBox from "../common/ResponsiveBox";
import ConstraintedBox from "../common/ConstraintedBox";

const TermsForm = () => {
  return (
    <div className="text-left p-4 font-medium ">
      <h2>Terms & Conditions</h2>
      <p>
        BY ACCEPTING THE TERMS AND CONDITIONS (This page contains said terms and
        conditions) ON JOTFORM YOU AGREE TO GO INTO CONTRACT WITH THE MEMBERS OF
        BETALLSOFTWARE (members of betallsoftware are apart of the following
        facebook page:
        <a href="https://www.facebook.com/profile.php?id=61554319537947">
          https://www.facebook.com/profile.php?id=61554319537947
        </a>
        ): By accepting these terms and conditions you agree to allow us to send
        you text messages and emails in which you can opt out of at any time.
      </p>
      <p>Please Ensure You Read The Following Terms And Conditions:</p>
      <section>
        <h3>1. Definitions and Interpretation</h3>
        <section>
          <h4>1.1 Definitions</h4>
          <ul>
            <li>
              <strong>Business Day</strong> means a day which is not a Saturday,
              Sunday or bank or public holiday in New South Wales.
            </li>
            <li>
              <strong>Betting Bank Account</strong> means a new bank account set
              up in your name with your ID that is used only to make deposits
              into and withdrawals out of betting accounts as part of
              BetAllSoftware’s daily operations.
            </li>
            <li>
              <strong>Parties</strong> means the parties entering into this
              Agreement.
            </li>
            <li>
              <strong>BetAllSoftware</strong>,{" "}
              <strong>
                https://www.facebook.com/profile.php?id=61554319537947
              </strong>
              , ‘we’, ‘us’, or ‘our’ means BetAllSoftware, and all members that
              belong to BetAllSoftware.
            </li>
            <li>
              <strong>Tax</strong> means a tax (including any goods and services
              tax), rate, levy, duty or impost (other than a tax on the income
              of the Lender) and any interest, penalty, expense or fine in
              connection with any of them.
            </li>
          </ul>
        </section>
        <section>
          <h4>1.2 Interpretation</h4>
          <ul>
            <li>
              (a) words in the singular include the plural and vice versa;
            </li>
            <li>
              (b) headings are for convenience and do not affect the
              interpretation of this Agreement;
            </li>
            <li>(c) any gender includes the other gender;</li>
            <li>
              (d) a reference to a clause, paragraph, schedule or annexure is a
              reference to a clause, paragraph, schedule or annexure, as the
              case may be, of this Agreement;
            </li>
            <li>
              (e) if any act which must be done under this Agreement is to be
              done on a day that is not a Business Day then the act must be done
              on or by the next Business Day;
            </li>
            <li>
              (f) a reference to any legislation includes subordinate
              legislation and all amendments, consolidations or replacements
              from time to time;
            </li>
            <li>
              (g) if a word or phrase is defined in this Agreement then any
              other grammatical form of the word or phrase shall have a
              corresponding meaning;
            </li>
            <li>
              (h) a reference to a natural person includes a body corporate,
              partnership, joint venture, association, government or statutory
              body or authority or other legal entity;
            </li>
            <li>
              (i) “includes” and similar words mean includes without limitation;
            </li>
            <li>
              (j) no clause of this Agreement shall be interpreted to the
              disadvantage of a Party merely because that Party drafted the
              clause or would otherwise benefit from it;
            </li>
            <li>
              (k) a reference to a Party includes the Party’s legal personal
              representatives, successors, assigns and persons substituted by
              novation;
            </li>
            <li>
              (l) a reference to this or any other agreement includes the
              agreement, all schedules and annexures as novated, amended or
              replaced and despite any change in the identity of the parties;
            </li>
            <li>
              (m) a reference to a covenant, obligation or agreement of two or
              more persons binds or benefits them jointly and severally;
            </li>
            <li>
              (n) a reference to time is to local time in New South Wales; and
            </li>
            <li>
              (o) a reference to “$” or “dollars” refers to the currency of
              Australia from time to time.
            </li>
            <li>
              (p) a reference to “$X” or “X dollars” refers to the dollar amount
              listed on the website at the time of submission for the program
              being signed up to.
            </li>
          </ul>
        </section>
      </section>
      <section>
        <h3>2. Payment to You</h3>
        <section>
          <h4>2.1 Choice of payment</h4>
          <p>
            (a) BetAllSoftware offers to pay you $200 to a bank account of your
            choosing in exchange for the sole and exclusive right to create or
            use any betting accounts in your name, along with the right to fund
            those accounts with a betting bank account created in your name for
            the purposes of betting and transferring capital and profits of
            betting activities to other BetAllSoftware bank accounts. This
            includes, but is not limited to, making deposits, withdrawing and
            betting with online bookmakers.
          </p>
          <p>
            (b) The distribution of this payment is subject to BetAllSoftware’s
            absolute discretion.
          </p>
          <p>
            (c) If your ID is insufficient to verify with the bookmaker TAB, the
            scheduled payment will be cancelled. BetAllSoftware will retain the
            right to use your ID for other bookmakers for the duration of this
            agreement.
          </p>
          <p>
            (d) You are expected to provide reasonable assistance towards
            BetAllSoftware throughout the initial process to get your accounts
            up and running.
          </p>
          <p>
            (e) If you wish to use some of the betting sites listed in s 4.1 or
            your accounts are already promotion banned your once off fee may be
            reduced. If this is so, the amount received will be that agreed to
            in a Facebook chat with BetAllSoftware.
          </p>
        </section>
      </section>
      <section>
        <h3>3. Betting Bank Account</h3>
        <section>
          <h4>3.1 Account creation and usage of bank card</h4>
          <p>
            You agree to let BetAllSoftware use your personal information to set
            up a Betting Bank Account (the bank account that will be created is
            up to Blue Sky Betting’s discretion) and to order a debit card
            linked to your account (TO EITHER: A) your current residential
            address or B) to a PO Box that belongs to agents of BetAllSoftware)
            . In the case of A, sending the debit card to you current
            residential address, you agree to provide images and details of said
            debit card upon BetAllSoftware’s request. The bank that will be used
            to create the betting bank account is at the sole discretion of Blue
            Sky Betting. You agree to A) help verify this bank if verification
            is required B) To allow multiple banks to be created incase the
            original bank is to be locked. You also agree to allow the creation
            of a new email and phone number with your personal details for the
            sole purpose of : A) allowing the creation of the betting bank
            account B) allow the creation of betting accounts C) to allow ease
            of communication on your behalf with the created betting bank
            account D) to allow the ease of communication on your behalf with
            the created betting accounts E) To allow ease of access and use of
            these accounts
          </p>
        </section>
        <section>
          <h4>3.2 Use of account</h4>
          <p>
            (a) You agree to let BetAllSoftware make transactions on your
            Betting Bank Account in your name for the purposes of betting. This
            includes, but is not limited to, making deposits to online
            bookmakers and transferring capital and profits of betting
            activities to other BetAllSoftware bank accounts.
          </p>
          <p>
            (b) BetAllSoftware agrees to only use your Betting Bank Account for
            the express purpose of betting and transferring capital and profits
            of betting activities to other BetAllSoftware bank accounts. .
            BetAllSoftware agrees not to conduct any other transactions in your
            name. BetAllSoftware agrees to not make any applications for credit
            on your behalf, or raise funds in your name without your express
            written permission.
          </p>
        </section>
        <section>
          <h4>3.3 Ownership of account funds</h4>
          <p>
            (a) You agree that all funds deposited and withdrawn into your
            Betting Bank Account are always the property of BetAllSoftware and
            remain the property of BetAllSoftware at all times.
          </p>
          <p>
            (b) You agree that all funds in the Betting Bank Account or your
            betting accounts are not yours and that you are not authorised to
            make any transactions on your Betting Bank Account without Blue Sky
            Betting’s express permission.
          </p>
          <p>
            (c) You agree that any funds transferred by You from your Betting
            Bank Account without permission is held to constitute a theft of
            BetAllSoftware’s property. You agree to repay all funds in addition
            to any costs incurred by BetAllSoftware for the recovery of funds.
          </p>
        </section>
      </section>
      <section>
        <h3>4. Betting Accounts</h3>
        <section>
          <h4>4.1 Account creation</h4>
          <p>
            A) Use your personal information to set up betting accounts with all
            current and future bookmakers licensed in Australia. This includes,
            but is not limited to Bet365, BetEasy, Betfair, Bluebet, PlayUp,
            Neds, Palmerbet, Pointsbet, Sportsbet, Sportsbetting, TAB, Topsport,
            Betdogs, Unibet, Vicbet, Ladbrokes, Tabtouch, Bbet, and Sport
            Champs.
          </p>
          <p>B) Fund these accounts with your Betting Bank Account.</p>
          <p>
            C) Allow the creation of a new phone number and email address
            SEPERATE TO YOUR CURRENT PERSONAL PHONE NUMBER/ EMAIL with the
            personal details you provide us to create these betting accounts and
            betting bank account (as these accounts require a phone number and
            email to be created), this phone number and email will be SEPERATE
            to your personal phone and email, this is to ensure you A) dont get
            constant marketing messages from these companies, B) To allow and
            ensure ease of communication on your behalf to these betting
            companies and the betting bank account company C) To allow ease of
            access and use between us and any betting accounts and betting bank
            account we create. D) To allow ease of communication on your behalf
            to set up any betting accounts or betting bank accounts in your name
            E) To allow ease of communication on your behalf with betting
            accounts and betting bank accounts for any support required to
            operate the betting accounts and betting bank account F) to allow
            ease of communication with the betting bank accounts and betting
            accounts in relation to withdrawing any funds which have been
            deposited by BetAllSoftware to BetAllSoftware bank accounts.
          </p>
        </section>
        <section>
          <h4>4.2 Use of account</h4>
          <p>
            (a) You agree to let BetAllSoftware make transactions on your
            betting accounts and bank account, on an ongoing basis, in your name
            for the purposes of betting and transferring capital and profits of
            betting activities to other Blue Sky Betting bank accounts.. This
            includes, but is not limited to, making deposits, withdrawing and
            betting with online bookmakers.
          </p>
          <p>
            (b) BetAllSoftware agrees to only use your betting accounts and bank
            account for the express purpose of betting and transferring capital
            and profits of betting activities to other BetAllSoftware bank
            accounts.. BetAllSoftware agrees not to conduct any other
            transactions in your name or conduct in any form of money
            laundering.
          </p>
          <p>
            (c) BetAllSoftware agrees to Only use your the phone number and
            email created with your personal details (clause 4.1 C) for the sole
            and express purpose of betting, creating betting accounts and a
            betting bank account and communicating to any betting companies/
            betting bank account companies on your behalf if need be.
          </p>
          <p>
            (d) You Agree to: NOT use any betting/ bank accounts made by
            BetAllSoftware , attempt to access these accounts or create a new
            accounts under your name for the duration of use by BetAllSoftware.
            Any of these actions will be viewed as egregious and will incur
            immediate action as this will be a direct violation of the terms and
            conditions. If you wish to access these betting/bank accounts, your
            interest must be given to us in writing via BetAllSoftware facebook
            messenger with express permission given to you by BetAllSoftware VIA
            FB messenger.
          </p>
          <p>
            (e) You agree to: Allow BetAllSoftware to contact any betting
            account companies and betting bank account companies that
            BetAllSoftware has created on your behalf to do the following: A) To
            allow and ensure ease of communication on your behalf to these
            betting companies and the betting bank account company B) To allow
            ease of access and use between us and any betting accounts and
            betting bank account we create. C) To allow ease of communication on
            your behalf to set up any betting accounts or betting bank accounts
            in your name D) To allow ease of communication on your behalf with
            betting accounts and betting bank accounts for any support required
            to operate the betting accounts and betting bank account E) to allow
            ease of communication with the betting bank accounts and betting
            accounts in relation to withdrawing any funds which have been
            deposited by BetAllSoftware to BetAllSoftware bank accounts.
          </p>
        </section>
        <section>
          <h4>4.3 Ownership of account funds</h4>
          <p>
            (a) You agree that all funds deposited and withdrawn into your
            betting accounts are always the property of BetAllSoftware and
            remain the property of Blue Sky Betting.
          </p>
          <p>
            (b) You agree that all funds in the betting account or your betting
            accounts are not yours and that you are not authorised to make any
            transactions on your betting accounts without BetAllSoftware’s
            express permission.
          </p>
          <p>
            (c) You agree that any funds transferred by You from your betting
            accounts without permission is held to constitute a theft of
            BetAllSoftware’s property. You agree to repay all funds in addition
            to any costs incurred by BetAllSoftware for the recovery of funds.
          </p>
        </section>
      </section>
      <section>
        <h3>5. Information</h3>
        <section>
          <h4>
            5.1 You agree to provide all information required to set up a
            Betting Bank Account and betting accounts. You further agree to
            provide all information required to satisfy any bookmaker or bank of
            your identity and to comply with anti-money laundering legislation.
            In relation to TAB: Rarely TAB requires verification to be done at a
            physical TAB venue, if this occurs BetAllSoftware will require you
            to go into a physical TAB venue to verify the said TAB account,
            failure to comply with this will incur a deduction of $50 from the
            original $200 payment (as $200 is paid for ALL accounts to be
            verified), If BetAllSoftware requires you to engage in further
            action to set up your accounts, you may be entitled to a one-time
            payment for your services, subject to BetAllSoftware’s discretion.
          </h4>
        </section>
        <section>
          <h4>
            5.2 To satisfy clause 5.1, you agree to provide all identity
            information requested by BetAllSoftware which includes but is not
            limited to:
          </h4>
          <p>
            (a) Your full name, current residential address, phone number and
            best email address for contact.
          </p>
          <p>
            (b) 100 points of ID. Your primary document may be your birth
            certificate, Australian citizenship certificate, Australian passport
            or international passport.
          </p>
          <p>
            (c) Secondary documents which may include, but are not limited to,
            the front and back of your driver’s licence, government issued photo
            card, utility bills showing your residential address, proof of age
            card, electoral roll registration.
          </p>
          <p>
            (d) A photo of you holding your ID in a selfie to ensure that no 3rd
            party is posting your ID information without your consent.
          </p>
          <p>
            (e) You agree to: Allow BetAllSoftware to contact any betting
            account companies and betting bank account companies that
            BetAllSoftware has created on your behalf to do the following: A) To
            allow and ensure ease of communication on your behalf to these
            betting companies and the betting bank account company B) To allow
            ease of access and use between us and any betting accounts and
            betting bank account we create. C) To allow ease of communication on
            your behalf to set up any betting accounts or betting bank accounts
            in your name D) To allow ease of communication on your behalf with
            betting accounts and betting bank accounts for any support required
            to operate the betting accounts and betting bank account E) to allow
            ease of communication with the betting bank accounts and betting
            accounts in relation to withdrawing any funds which have been
            deposited by BetAllSoftware to BetAllSoftware bank accounts.
          </p>
        </section>
        <section>
          <h4>
            5.3 BetAllSoftware agrees to only use the information you provide
            for the sole purpose of betting. Your information will only be used
            to create a bank account, betting accounts, and meet regulatory
            requirements, as well as creating a new number and email with your
            personal details to allow ease of communication on your behalf with
            these betting companies (as mentioned in clause 4.1 C and 4.2 C)
          </h4>
        </section>
        <section>
          <h4>
            5.4 Storage And Collection of personal information: You agree to the
            following terms and conditions relating to the storage and
            collection of your personal data:
          </h4>
          <p>
            (a) All personal data is downloaded from jotform and stored away in
            a secure 2FA google drive ( 2 factor authentication) chamber to
            completely prevent any data breaches (except for privacy breaches
            occurring on and affecting googles servers,)
          </p>
          <p>
            (b) Data is collected on a secure jotform
            (https://www.jotform.com/), this is the form you use to sign up. You
            agree to jot forms privacy policy: https://www.jotform.com/privacy/
          </p>
        </section>
      </section>
      <section>
        <h3>6. Representations and warranties</h3>
        <section>
          <h4>6.1 Nature</h4>
          <p>You represent and warrant that:</p>
          <p>
            (a) You are not a minor and do not suffer from any legal incapacity
            which in any way affects, or might in the future affect, your
            ability to execute or deliver or comply with your obligations under
            this Agreement;
          </p>
          <p>
            (b) this Agreement constitutes its valid and binding obligations and
            is enforceable against it by Blue Sky Betting in accordance with its
            terms;
          </p>
          <p>
            (c) the execution and delivery of, and the compliance with its
            obligations under, this Agreement do not contravene any law or
            directive from a government body, any agreement or instrument to
            which it is a party, any of its obligations to any other person or
            (where it is a company) its constitutional documents;
          </p>
          <p>
            (d) all information given to BetAllSoftware in relation to this
            Agreement is correct, complete and not misleading and it has
            disclosed to Blue Sky Betting all information which it has or has
            access to which is relevant to BetAllSoftware setting up a bank
            account and betting accounts.
          </p>
        </section>
        <section>
          <h4>6.2 General</h4>
          <p>
            (a) You acknowledge that BetAllSoftware enters into this Agreement
            in reliance on your representations and warranties.
          </p>
          <p>
            (b) Each representation and warranty survives the execution of this
            Agreement and is deemed repeated with reference to the facts and
            circumstances then existing on each day the Agreement remains in
            place.
          </p>
        </section>
        <section>
          <h3>7. Indemnities</h3>
          <section>
            <p>
              Each indemnity in this Agreement is a continuing obligation,
              separate and independent from the other obligations of the
              Borrower and survives the termination of this Agreement.
            </p>
          </section>
        </section>
        <section>
          <h3>8. Termination of agreement</h3>
          <section>
            <h4>
              8.1 All parties to this agreement will retain a right to terminate
              the agreement at any time. To exercise this right, a party needs
              to communicate their intent to terminate the agreement to the
              other party.
            </h4>
          </section>
        </section>
        <section>
          <h4>
            8.2 For the avoidance of all doubt, if you provide insufficient
            identification to create a Betting Bank Account or betting accounts,
            BetAllSoftware reserves the right to immediately terminate this
            agreement, and not pay you any money.
          </h4>
        </section>
        <section>
          <h4>8.3 In the event of termination you agree that:</h4>
          <p>
            (a) All funds in any of your betting accounts or your betting bank
            account will be returned to Blue Sky Betting.
          </p>
          <p>
            (b) BetAllSoftware has two weeks access to your betting accounts and
            Betting Bank Account to allow funds to be returned. This access is
            lengthened if any of your betting accounts have any restrictions
            placed on the ability to withdraw funds.
          </p>
        </section>
        <section>
          <h4>
            8.4 Notwithstanding clause 8.1, if you were paid a once off fee as
            described in s 2.3 then Blue Sky Betting may use your Betting Bank
            Account and betting accounts for a minimum of one year unless you
            repay all payments made to You.
          </h4>
        </section>
      </section>
      <section>
        <h3>9. Supervening legislation</h3>
        <section>
          <p>
            Any present or future legislation which operates to lessen or vary
            in favour of You any obligations in connection with this Agreement
            or to postpone, stay, suspend or curtail any rights of
            BetAllSoftware under this Agreement is excluded except to the extent
            that its exclusion is prohibited or rendered ineffective by law.
          </p>
        </section>
      </section>
      <section>
        <h3>10. Amendment</h3>
        <section>
          <p>
            This Agreement may only be amended by written agreement executed by
            all the Parties.
          </p>
        </section>
      </section>
      <section>
        <h3>11. Notices</h3>
        <section>
          <h4>11.1 Form of notice</h4>
          <p>
            A notice or other communication must be in writing in English and
            may be:
          </p>
          <p>(a) delivered personally;</p>
          <p>(b) given by an agent of the sender;</p>
          <p>
            (c) left at a Party’s current delivery address for notices as set
            out in this Agreement;
          </p>
          <p>
            (d) sent by prepaid mail to a Party’s current postal address for
            notices as set out in this Agreement;
          </p>
          <p>
            (e) sent by fax to a Party’s current fax number for notices as set
            out in this Agreement.
          </p>
          <p>
            (f) sent by email to a Party’s best email contact address. This is
            assumed to be the email addresses used for initial correspondence if
            no Party specifies its preferred email address.
          </p>
        </section>
        <section>
          <h4>11.2 Receipt of notice</h4>
          <p>A notice or communication is taken as having been given:</p>
          <p>
            (a) if sent via Facebook or email and acknowledged by the recipient.
          </p>
          <p>
            (b) This Agreement is not intended to create a partnership, joint
            venture or agency relationship between the Parties.
          </p>
        </section>
      </section>
      <section>
        <h3>12. Assignment</h3>
        <section>
          <p>
            (a) You must not assign or otherwise dispose of any right under this
            Agreement without the prior written consent of BetAllSoftware.
          </p>
          <p>
            (b) BetAllSoftware’s rights under this Agreement are assignable.
          </p>
        </section>
      </section>
      <section>
        <h3>13. Waiver or variation of rights</h3>
        <section>
          <p>
            (a) A right in favour of the You under this Agreement and a breach
            of an obligation of the Borrower under this Agreement can only be
            waived by an instrument duly executed by BetAllSoftware. No other
            act, omission or delay of the BetAllSoftware will constitute a
            waiver binding, or estoppel against BetAllSoftware.
          </p>
          <p>
            (b) A single or partial exercise or waiver by Blue Sky Betting of a
            right relating to this Agreement will not prevent any other exercise
            of that right or the exercise of any other right.
          </p>
        </section>
      </section>
      <section>
        <h3>14. Powers, rights and remedies</h3>
        <section>
          <p>
            Except as expressly stated to the contrary in this Agreement, the
            powers, rights and/or remedies of a Party under this Agreement are
            cumulative and are in addition to any other powers, rights and
            remedies of that Party. Nothing in this Agreement merges,
            extinguishes, postpones, lessens or otherwise prejudicially affects
            any power, right, or remedy that a Party may have at any time
            against the other Party to this Agreement or any other person.
          </p>
        </section>
      </section>
      <section>
        <h3>15. Further assurance</h3>
        <section>
          <p>
            You must from time to time and in a timely manner do all things
            reasonably required by BetAllSoftware to give effect to this
            Agreement.
          </p>
        </section>
      </section>
      <section>
        <h3>16. Counterparts</h3>
        <section>
          <p>
            (a) This Agreement may be executed in any number of counterparts
            and, if so, the counterparts taken together will constitute one and
            the same Agreement.
          </p>
          <p>
            (b) The date of this Agreement will be the date you agree to these
            terms.
          </p>
        </section>
      </section>
      <section>
        <h3>17. Entire agreement and understanding</h3>
        <section>
          <p>In respect of the subject matter of this Agreement:</p>
          <p>
            (a) this Agreement contains the entire understanding between the
            Parties;
          </p>
          <p>
            (b) all previous oral and written communications, representations,
            warranties or commitments are superseded by this Agreement and do
            not affect the interpretation or meaning of this Agreement;
          </p>
          <p>
            (c) each of the Parties has relied entirely on its own enquiries
            before entering into this Agreement.
          </p>
        </section>
      </section>
      <section>
        <h3>18. Governing law and jurisdiction</h3>
        <section>
          <p>(a) This Agreement is governed by the laws of New South Wales.</p>
          <p>
            (b) Each Party irrevocably and unconditionally submits to the
            non‑exclusive jurisdiction of the courts of New South Wales.
          </p>
        </section>
      </section>
    </div>
  );
};

export default TermsForm;
