import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faGithub,
    faLinkedin,
    faTelegram,
    faInstagram,
    faXTwitter,
    faWhatsapp,
    faSnapchat,
    faFacebook,
} from "@fortawesome/free-brands-svg-icons";
import Strings from "../constants/strings";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

const socialLinks = [
    {
        name: Strings.mail,
        url: Strings.mailLink,
        icon: <FontAwesomeIcon icon={faEnvelope} />,
        text: Strings.mailUsername,
    },
    {
        name: Strings.whatsApp,
        url: Strings.whatsAppLink,
        icon: <FontAwesomeIcon icon={faWhatsapp} />,
        text: Strings.whatsAppUsername,
    },
    {
        name: Strings.telegram,
        url: Strings.telegramLink,
        icon: <FontAwesomeIcon icon={faTelegram} />,
        text: Strings.telegramUsername,
    },
    {
        name: Strings.facebook,
        url: Strings.facebookLink,
        icon: <FontAwesomeIcon icon={faFacebook} />,
        text: Strings.facebookUsername,
    },
    {
        name: Strings.instagram,
        url: Strings.instagramLink,
        icon: <FontAwesomeIcon icon={faInstagram} />,
        text: Strings.instagramUsername,
    },
    {
        name: Strings.snapChat,
        url: Strings.snapChatLink,
        icon: <FontAwesomeIcon icon={faSnapchat} />,
        text: Strings.snapChatUsername,
    },
];

export default socialLinks;
