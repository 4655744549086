import React from "react";

const PrivacyForm = () => {
  return (
    <div className="text-left text-xl sm:text-3xl p-4 font-medium ">
      <h3>Privacy Policy</h3>
      <p>Last Updated: 10th August 2024</p>
      <section className="mt-5">
        <h4 className="font-bold">1. INTRODUCTION</h4>
        <p>
          BetAllSoftware ("We", "Us", "Our") is committed to protecting and
          respecting your privacy. This privacy policy outlines how we collect,
          use, and protect your personal information. This policy applies to our
          website, mobile applications, services, and products (collectively
          "Services").
        </p>
      </section>
      <section className="mt-5">
        <h4 className="font-bold">2. THE INFORMATION WE COLLECT</h4>
        <p>
          We may collect and process the following data about you: <br />
          <br />
          Personal identification information (First name, Last name) Contact
          information (Phone number, Email) Sign-in details (Username, Password)
        </p>
      </section>
      <section className="mt-5">
        <h4 className="font-bold">3. HOW WE COLLECT YOUR DATA</h4>
        <p>
          We collect personal data when you: <br />
          <br />
          Enquire online or use one of our services. Use or view our website via
          your browser's cookies.
        </p>
      </section>
      <section className="mt-5">
        <h4 className="font-bold">4. HOW WE USE YOUR DATA</h4>
        <p>
          We collect your data so that we can: <br />
          <br />
          Process your queries, manage your account. Email you with required
          documents or other services we think you might like. Enable secure
          access to our services through sign-in details. To comply with legal
          and regulatory requirements.
        </p>
      </section>
      <section className="mt-5">
        <h4 className="font-bold">5. HOW WE STORE YOUR DATA</h4>
        <p>
          We securely store your data encrypted on a dedicated storage drive
          operated by BetAllSoftware. Your sign-in details are stored securely
          and encrypted to prevent unauthorised access. We will keep your data
          for no longer than 12 months or as long as required by Australian Law.
        </p>
      </section>
      <section className="mt-5">
        <h4 className="font-bold">6. MARKETING</h4>
        <p>
          With your permission and/or where permitted by law, we may also use
          your data for marketing purposes, which may include contacting you by
          email or telephone with information, news, and offers on our Services.
        </p>
      </section>
      <section className="mt-5">
        <h4 className="font-bold">7. YOUR DATA PROTECTION RIGHTS</h4>
        <p>
          Under Australian law, you have rights including: <br />
          <br />
          Your right to access - You have the right to request us for copies of
          your personal information.
          <br />
          Your right to rectification - You have the right to request that we
          correct any information you believe is inaccurate or complete the
          information you believe is incomplete. <br />
          Your right to erasure - You have the right to request that we erase
          your personal information, under certain conditions. <br />
          Your right to restriction of processing - You have the right to
          request that we restrict the processing of your personal information,
          under certain conditions. <br />
          Your right to object to processing - You have the right to object to
          our processing of your personal information, under certain conditions.{" "}
          <br />
          Your right to data portability - You have the right to request that we
          transfer the data that we have collected to another organisation, or
          directly to you, under certain conditions. <br />
          If you wish to exercise any of these rights, please contact us at
          betallsoftware@gmail.com
        </p>
      </section>
      <section className="mt-5">
        <h4 className="font-bold">8. CHANGES TO OUR PRIVACY POLICY</h4>
        <p>
          We may change this privacy policy from time to time. The updated
          policy will be posted on our website. We encourage you to check this
          policy regularly.
        </p>
      </section>
      <section className="mt-5">
        <h4 className="font-bold">9. HOW TO CONTACT US</h4>
        <p>
          If you have any questions about this privacy policy, the data we hold
          on you, or you would like to exercise one of your data protection
          rights, please do not hesitate to contact us at
          betallsoftware@gmail.com
        </p>
      </section>
    </div>
  );
};

export default PrivacyForm;
