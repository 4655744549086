import React from "react";
import { useNavigate } from "react-router-dom";

const AccountSection5 = () => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate("/account_form", { replace: true });
    };
    return (
        <div className="bg-[var(--bgColor)] font-poppins-semibold bg-cover text-black">
            <div className="sm:w-[850px] mx-auto w-full">
                <div className="pt-[70px] max-sm:px-6">
                    <div>
                        <div className="z-0 relative">
                            <img
                                src="/images/accounts/cross.png"
                                alt="cross"
                                style={{
                                    objectFit: "cover",
                                    width: "165px",
                                }}
                                className="absolute -ml-5 -mt-5 max-sm:hidden"
                            />
                        </div>
                        <div className="bg-white rounded-lg  px-8 z-10 relative">
                            <div className="items-center py-16">
                                <img
                                    src="/images/accounts/Heads.png"
                                    alt="speaker"
                                    style={{
                                        objectFit: "cover",
                                        width: "600px",
                                    }}
                                    className="m-auto"
                                />
                                <div className="sm:text-4xl text-green-900 mx-auto pt-16 text-3xl">
                                    Get started now with BetAllSoftware.
                                </div>
                            </div>
                            <div className="font-poppins-regular">
                                <div
                                    className="text-white text-lg bg-purple-600 rounded-3xl w-[280px] h-[55px] tracking-widest border-2 border-transparent hover:border-purple-600 hover:bg-white hover:text-purple-600 duration-200 cursor-pointer p-3 mx-auto"
                                    onClick={handleClick}
                                >
                                    GET STARTED NOW
                                </div>
                            </div>
                            <img
                                src="/images/accounts/rocket.png"
                                alt="cross"
                                style={{
                                    objectFit: "cover",
                                    width: "87px",
                                }}
                                className="mx-auto pb-6"
                            />
                        </div>
                        <img
                            src="/images/accounts/cross.png"
                            alt="cross"
                            style={{
                                objectFit: "cover",
                                width: "165px",
                            }}
                            className="absolute -mt-[145px] ml-[705px]"
                        />
                    </div>
                </div>
                <div className="pt-[80px]"></div>
            </div>
        </div>
    );
};

export default AccountSection5;
