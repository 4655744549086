import React from "react";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useNavigate } from "react-router-dom";

const AccountSection1 = () => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate("/account_form", { replace: true });
    };
    return (
        <div className="bg-[#001427] font-poppins-semibold">
            <div className="sm:w-[1160px] mx-auto w-full">
                <div className="grid sm:grid-cols-2 text-left">
                    <div className="mt-[26px]">
                        <div className="w-[200px] max-sm:mx-auto">
                            <img
                                src="/images/accounts/logo.png"
                                alt="logo"
                                style={{
                                    objectFit: "cover",
                                    width: "100%",
                                }}
                            />
                        </div>
                        <div className="sm:mt-[30px] space-y-3 sm:text-6xl mt-[30px] max-sm:text-center text-4xl">
                            <div>Sell Your Sports</div>
                            <div>Betting Accounts.</div>
                            <div className="text-green-500 sm:border-b sm:border-b-green-500 pt-4">
                                Earn $200 Today.
                            </div>
                        </div>
                        <div className="mt-[65px] text-white text-2xl font-poppins space-y-2 max-sm:text-center">
                            <div>We'll buy your betting accounts for $200.</div>
                            <div>Safe. Secure. Hassle-free.</div>
                        </div>
                        <div className="max-sm:mx-auto max-sm:w-full">
                            <div className="sm:mt-[80px] font-poppins-regular mt-[40px] max-sm:text-center">
                                <button
                                    className="text-black text-lg bg-yellow-500 rounded-3xl w-[350px] h-[55px] tracking-widest border border-white shadow-inner shadow-white hover:text-base hover:bg-black hover:text-green-500 duration-200"
                                    onClick={handleClick}
                                >
                                    GET STARTED NOW
                                </button>
                            </div>
                            <div className="flex gap-6 sm:mt-5 sm:ml-9 max-sm:justify-center mt-3">
                                <div className="flex items-center text-xs gap-2">
                                    <div>
                                        <VerifiedIcon fontSize="small" />
                                    </div>
                                    <div>Safe</div>
                                </div>
                                <div className="flex items-center text-xs gap-2">
                                    <div>
                                        <VerifiedIcon fontSize="small" />
                                    </div>
                                    <div>Secure</div>
                                </div>
                                <div className="flex items-center text-xs gap-2">
                                    <div>
                                        <VerifiedIcon fontSize="small" />
                                    </div>
                                    <div>Compliant</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sm:mt-[120px] sm:ml-[100px] relative">
                        <div className="sm:w-[177px] absolute hidden">
                            <img
                                src="/images/accounts/cross.png"
                                alt="logo"
                                style={{
                                    objectFit: "cover",
                                    width: "100%",
                                }}
                            />
                        </div>

                        <div className="sm:w-[204px] sm:absolute sm:bottom-[125px] sm:-right-[110px] hidden">
                            <img
                                src="/images/accounts/cross.png"
                                alt="logo"
                                style={{
                                    objectFit: "cover",
                                    width: "100%",
                                }}
                            />
                        </div>
                        <div className="sm:w-[540px] sm:absolute w-full max-sm:p-10">
                            <img
                                src="/images/accounts/horse_riding.jpeg"
                                alt="logo"
                                style={{
                                    objectFit: "cover",
                                    width: "100%",
                                }}
                                className=" rounded-3xl mt-6 ml-6 max-sm:mx-auto opacity-80"
                            />
                        </div>
                    </div>
                </div>
                <div className="sm:mt-[30px] font-poppins">
                    <div className="relative left-7 top-7">Facebook Rating</div>
                    <a
                        href="https://www.facebook.com/profile.php?id=61554319537947"
                        target="_blank"
                    >
                        <img
                            src="/images/accounts/facebook_rating.png"
                            alt="logo"
                            className="mx-auto"
                            style={{
                                objectFit: "cover",
                                width: "232px",
                            }}
                        />
                    </a>
                    <div className="relative -top-6">
                        Check out our 56+ reviews
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountSection1;
