import React from "react";
import PageBox from "../../components/common/PageBox";
import Footer from "../../components/home/Footer";
import NavBar from "../../components/navbar/NavBar";
import AccountWaitList from "../../components/home/AccountWaitList";

export default function WaitListPage() {
    return (
        <>
            <NavBar />
            <PageBox>
                <AccountWaitList />
                {/* <Footer bgType={1} /> */}
            </PageBox>
        </>
    );
}
