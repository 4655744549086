import React from "react";

import { useRef } from "react";
import ConstraintedBox from "../common/ConstraintedBox";
import ResponsiveBox from "../common/ResponsiveBox";
import ReactPlayer from "react-player";

const VideoSection = ({ url, width, height }) => {
    const homeRef = useRef(null);

    return (
        <ResponsiveBox
            classNames="bg-[var(--dialogColor)] min-h-[30vh] sm:min-h-[80vh] items-center justify-center"
            id="about"
            elementRef={homeRef}
        >
            <ConstraintedBox classNames="p-4 pb-16 pt-8 sm:pt-16">
                {/* <div className="text-center mx-auto mb-10 sm:mb-20 text-xl sm:text-5xl font-medium">
          You can watch our demo video to understand how the program works
        </div> */}
                <div className="text-center mx-auto mb-10 sm:mb-20 text-md sm:text-3xl font-medium">
                    Below we have attached a demo video to provide assistance in
                    understanding how the program works
                </div>
                <div className="justify-items-center mx-auto">
                    <ReactPlayer
                        controls={true}
                        width={width}
                        height={height}
                        url={url}
                    />
                </div>
            </ConstraintedBox>
        </ResponsiveBox>
    );
};

export default VideoSection;
