const navMenus = [
    {
        title: "RENT YOUR ACCOUNT TO US",
        path: "/accounts",
        section: "accounts",
    },
    // {
    //     title: "LIVE RACING SOFTWARE",
    //     path: "/software",
    //     section: "software",
    // },
    {
        title: "ACCOUNT CREATOR BOT",
        path: "/signupbot",
        section: "signupbot",
    },
    {
        title: "WAITLIST FOR ACCOUNT RENTALS",
        path: "/wait_list",
        section: "waitlist",
    },
    // {
    //     title: "WITHDRAW AND DEPOSIT FORM",
    //     path: "/account_form",
    //     section: "accountform",
    // },
    {
        title: "REVIEWS",
        path: "/peoples_reviews",
        section: "peoplesreviews",
    },
    {
        title: "PROMOTION SOFTWARE",
        path: "/promotion_software",
        section: "promotionsoftware",
    },
    // {
    //     title: "TERMS & CONDITIONS",
    //     path: "/terms_conditions",
    //     section: "termsconditions",
    // },
];

export default navMenus;
