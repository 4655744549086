import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";
import { getStorage } from "firebase/storage";
import React, { useEffect, useRef, useState } from "react";
import SignaturePad from "react-signature-canvas";

function Signature({ signUrl, setSignUrl, folder }) {
  const [imgStatus, setImgStatus] = useState("");

  const sigCanvas = useRef({});
  const clear = async () => {
    const storage = getStorage();
    const storageRef = ref(storage, signUrl);
    setImgStatus("File Deleting!");
    try {
      await deleteObject(storageRef);
      setSignUrl("");
      setImgStatus("Deleted!");
    } catch (e) {
      setImgStatus("Deleted!");
    }
    sigCanvas.current.clear();
  };

  const saveSignatureToFirebase = async (e) => {
    e.preventDefault();
    const storage = getStorage();
    const delStorageRef = ref(storage, signUrl);
    try {
      await deleteObject(delStorageRef);
    } catch (e) {}
    // Get the signature data URL from the canvas
    setImgStatus("Saving");
    const signatureDataURL = sigCanvas.current.toDataURL();

    // Create a Blob object from the data URL
    const blob = await fetch(signatureDataURL).then((res) => res.blob());

    // Initialize Firebase Storage
    const filename = `signature_${new Date().getTime()}.png`;
    const storageRef = ref(storage, folder + "/" + filename);
    await uploadBytes(storageRef, blob);
    const downloadURL = await getDownloadURL(storageRef);
    setSignUrl(downloadURL);

    setImgStatus("Saved!");
  };

  useEffect(() => {
    // Access the SignaturePad instance and set its background color
    const signaturePad = sigCanvas.current.getSignaturePad();
    if (signaturePad) {
      signaturePad.penColor = "black"; // Example: change pen color
      signaturePad.backgroundColor = "lightgray"; // Change background color
    }
  }, []);

  return (
    <div className="text-base">
      <div className="bg-gray-200 w-[300px]">
        <SignaturePad
          ref={sigCanvas}
          canvasProps={{
            className: "signatureCanvas",
            width: 300,
            height: 150,
          }}
        />
      </div>
      {/* <button onClick={save}>Save</button> */}
      <span className="flex">
        <button
          onClick={(e) => {
            e.preventDefault();
            clear();
          }}
          className="mr-2 mt-2 bg-red-600 text-white p-2 rounded-md cursor-pointer"
        >
          Clear
        </button>
        <button
          onClick={saveSignatureToFirebase}
          className="mr-2 mt-2 bg-green-600 text-white p-2 rounded-md cursor-pointer"
        >
          Save
        </button>
        <div className="ml-2 mt-2 text-black text-base p-2">{imgStatus}</div>
      </span>
      {imgStatus === "Saved!" ? null : (
        <div className="mt-2 text-sm text-red-600">
          * Click "Save" when signature is complete
        </div>
      )}
    </div>
  );
}

export default Signature;
