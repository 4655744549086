const signupBotImages = {
    slideImages: [
        {
            url: "/images/signupbot/image1.png",
            caption: "Settings",
        },
        {
            url: "/images/signupbot/image2.png",
            caption: "Main Page",
        },
    ],
};

export default signupBotImages;
