import React, { useEffect, useState } from "react";

import { useRef } from "react";
import ResponsiveBox from "../common/ResponsiveBox";
import ConstraintedBox from "../common/ConstraintedBox";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import clsx from "clsx";
import { getStores } from "../../api/crud";
import { set } from "firebase/database";

const AccountWaitList = () => {
    const [rowData, setRowData] = useState([]);
    const autoSizeStrategy = {
        type: "fitGridWidth",
        defaultMinWidth: 50,
        columnLimits: [
            {
                colId: "no",
                minWidth: 50,
            },
            {
                colId: "name",
                minWidth: 200,
            },
            {
                colId: "referer",
                minWidth: 200,
            },
        ],
    };

    // Column Definitions: Defines & controls grid columns.
    const [colDefs, setColDefs] = useState([
        { field: "no" },
        { field: "date" },
        { field: "name" },
        { field: "accountType" },
        { field: "salesType" },
        { field: "referer" },
        { field: "status" },
    ]);

    useEffect(() => {
        const getSalesInfo = async () => {
            const salesInfos = await getStores("salesHistory");
            const items = Object.entries(salesInfos).map((item, index) => {
                let data = {
                    no: index + 1,
                    date: item[1].date,
                    name: item[1].name,
                    accountType: item[1].accountType,
                    salesType: item[1].salesType,
                    referer: item[1].referer,
                    status: item[1].salesStatus,
                };
                return data;
            });
            setRowData(items);
        };

        getSalesInfo();
    }, []);
    return (
        <ResponsiveBox classNames="bg-[var(--dialogColor)] min-h-[100vh] items-center justify-center">
            <ConstraintedBox classNames="p-4 py-12">
                <div className="text-center mx-auto mb-20 text-xl sm:text-3xl font-medium">
                    Since we have a lot of people interested in selling us their
                    accounts, we have a short waitlist, you can view the status
                    of the waitlist and your position in the queue as it
                    updates.
                    <div className="text-right text-xl m-4 text-gray-300">
                        (Note: It won't take long for us to get to you we move
                        fast)
                    </div>
                </div>
                <div
                    className={clsx("ag-theme-quartz-dark", "m-auto")}
                    style={{ width: "80%", height: "60vh" }}
                >
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={colDefs}
                        autoSizeStrategy={autoSizeStrategy}
                    />
                </div>
            </ConstraintedBox>
        </ResponsiveBox>
    );
};

export default AccountWaitList;
