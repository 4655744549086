import React from "react";
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
} from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

const AccountSection4 = () => {
    return (
        <div className="font-poppins bg-cover text-black bg-faq-img bg-opacity-10">
            <div className="sm:w-[730px] mx-auto w-full max-sm:px-6">
                <div className="py-16">
                    <div className="bg-white">
                        <div className="text-black text-4xl pt-10 font-poppins-semibold">
                            FAQs
                        </div>
                        <div className="w-full px-6">
                            <div className="mx-auto w-full divide-y divide-black/30 rounded-xl bg-white text-black/90">
                                <Disclosure
                                    as="div"
                                    className="px-1 py-6"
                                    defaultOpen={false}
                                >
                                    <DisclosureButton className="group flex w-full items-center justify-between">
                                        <span className="text-xl font-medium text-left">
                                            How does the $200 payout work?
                                        </span>
                                        <ChevronDownIcon className="size-5 fill-black/70 group-data-[open]:rotate-180" />
                                    </DisclosureButton>
                                    <DisclosurePanel className="mt-2 text-xl text-black/70 text-left">
                                        Express your interest by filling out the
                                        form. Takes only a few minutes. <br />
                                        <br />
                                        Upon creating and verifying your betting
                                        accounts, we'll send $200 to your
                                        specified bank via PayID within 5
                                        business days.
                                        <br />
                                        <br /> Payment is issued only upon
                                        account approval. Submitting the form
                                        alone doesn't warrant payment.
                                    </DisclosurePanel>
                                </Disclosure>
                                <Disclosure
                                    as="div"
                                    className="px-1 py-6"
                                    defaultOpen={false}
                                >
                                    <DisclosureButton className="group flex w-full items-center justify-between">
                                        <span className="text-xl font-medium text-left">
                                            No sports betting accounts?
                                        </span>
                                        <ChevronDownIcon className="size-5 fill-black/70 group-data-[open]:rotate-180" />
                                    </DisclosureButton>
                                    <DisclosurePanel className="mt-2 text-xl text-black/70 text-left">
                                        No problem! We create the accounts for
                                        you. No active involvement on your
                                        behalf necessary.
                                    </DisclosurePanel>
                                </Disclosure>
                                <Disclosure
                                    as="div"
                                    className="px-1 py-6"
                                    defaultOpen={false}
                                >
                                    <DisclosureButton className="group flex w-full items-center justify-between">
                                        <span className="text-xl font-medium text-left">
                                            How do I know this isn't a scam?
                                        </span>
                                        <ChevronDownIcon className="size-5 fill-black/70 group-data-[open]:rotate-180" />
                                    </DisclosureButton>
                                    <DisclosurePanel className="mt-2 text-xl text-black/70 text-left">
                                        We foster trust through clear
                                        communication, robust security measures,
                                        and transparency. Ask us anything.
                                        <br />
                                        <br /> Our Terms & Conditions provide a
                                        detailed outline of the agreement
                                        safeguarding both parties.
                                        <br />
                                        <br /> You can explore testimonials on
                                        our site for firsthand accounts of our
                                        reliability.
                                        <br /> For any queries, do not hesitate
                                        to get in touch with us.
                                    </DisclosurePanel>
                                </Disclosure>

                                <Disclosure
                                    as="div"
                                    className="px-1 py-6"
                                    defaultOpen={false}
                                >
                                    <DisclosureButton className="group flex w-full items-center justify-between">
                                        <span className="text-xl font-medium text-left">
                                            Is this legal?
                                        </span>
                                        <ChevronDownIcon className="size-5 fill-black/70 group-data-[open]:rotate-180" />
                                    </DisclosureButton>
                                    <DisclosurePanel className="mt-2 text-xl text-black/70 text-left">
                                        Absolutely! Selling sports accounts
                                        through our program is entirely legal.
                                        We stay current with all relevant
                                        regulations and diligently follow them.
                                        <br />
                                        <br />
                                        Our Terms and Conditions are designed
                                        with full compliance in mind, ensuring
                                        that our operations are always above
                                        board.
                                    </DisclosurePanel>
                                </Disclosure>

                                <Disclosure
                                    as="div"
                                    className="px-1 py-6"
                                    defaultOpen={false}
                                >
                                    <DisclosureButton className="group flex w-full items-center justify-between">
                                        <span className="text-xl font-medium text-left">
                                            Is my personal information safe and
                                            secure?
                                        </span>
                                        <ChevronDownIcon className="size-5 fill-black/70 group-data-[open]:rotate-180" />
                                    </DisclosureButton>
                                    <DisclosurePanel className="mt-2 text-xl text-black/70 text-left">
                                        Absolutely.
                                        <br />
                                        <br /> Our submission forms (Jotform)
                                        come with 256 bit SSL encryption which
                                        is the same protection level used by
                                        online banking or e-commerce providers.
                                        See here.
                                        <br />
                                        <br /> We value your privacy and take
                                        the security of your personal
                                        information seriously.
                                        <br />
                                        <br />
                                        Your data will never be shared or sold
                                        to any third parties.
                                    </DisclosurePanel>
                                </Disclosure>
                                <Disclosure
                                    as="div"
                                    className="px-1 py-6"
                                    defaultOpen={false}
                                >
                                    <DisclosureButton className="group flex w-full items-center justify-between">
                                        <span className="text-xl font-medium text-left">
                                            Which betting accounts do you
                                            create?
                                        </span>
                                        <ChevronDownIcon className="size-5 fill-black/70 group-data-[open]:rotate-180" />
                                    </DisclosureButton>
                                    <DisclosurePanel className="mt-2 text-xl text-black/70 text-left">
                                        We primarily create and purchase
                                        accounts from TAB, Sportsbet, and Betr.
                                    </DisclosurePanel>
                                </Disclosure>
                                <Disclosure
                                    as="div"
                                    className="px-1 py-6"
                                    defaultOpen={false}
                                >
                                    <DisclosureButton className="group flex w-full items-center justify-between">
                                        <span className="text-xl font-medium text-left">
                                            Why do we want your accounts?
                                        </span>
                                        <ChevronDownIcon className="size-5 fill-black/70 group-data-[open]:rotate-180" />
                                    </DisclosureButton>
                                    <DisclosurePanel className="mt-2 text-xl text-black/70 text-left">
                                        We use a profitable strategy called
                                        "matched betting" that leverages free
                                        bets and promotions.
                                        <br />
                                        <br /> By acquiring your accounts, we
                                        access these promos to fuel our betting
                                        approach.
                                        <br />
                                        <br /> Feel free to research "matched
                                        betting" for more details.
                                    </DisclosurePanel>
                                </Disclosure>
                                <Disclosure
                                    as="div"
                                    className="px-1 py-6"
                                    defaultOpen={false}
                                >
                                    <DisclosureButton className="group flex w-full items-center justify-between">
                                        <span className="text-xl font-medium text-left">
                                            Contact Us
                                        </span>
                                        <ChevronDownIcon className="size-5 fill-black/70 group-data-[open]:rotate-180" />
                                    </DisclosureButton>
                                    <DisclosurePanel className="mt-2 text-xl text-black/70 text-left">
                                        Facebook Messenger
                                    </DisclosurePanel>
                                </Disclosure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountSection4;
