import React, { useEffect, useState } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { saveStore } from "../../api/crud";
import DragDropFile from "../common/DragDropFile";
import Signature from "../common/Signature";
import InputBox from "../common/InputBox";
import { useRecoilState } from "recoil";
import {
  atom_card1,
  atom_card2,
  atom_card3,
  atom_card4,
  signatureImg,
} from "../../atom";
import { toast } from "react-toastify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import medicareImage from "../../assets/images/account_form/medicare.jpg";
import vicLicImage from "../../assets/images/account_form/vic_license.jpg";
import nswLicImage from "../../assets/images/account_form/nsw_license.jpg";
import qldLicImage from "../../assets/images/account_form/qld_license.jpg";
import moment from "moment/moment";

const mediaQuery = window.matchMedia("(max-width: 768px)");

const AccountForm = () => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dobDay, setDobDay] = useState("1");
  const [dobMonth, setDobMonth] = useState("1");
  const [dobYear, setDobYear] = useState("1990");
  const [addrUnit, setAddrUnit] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [streetName, setStreetName] = useState("");
  const [streetType, setStreetType] = useState("");
  const [suburb, setSuburb] = useState("");
  const [addrState, setAddrState] = useState("VIC");
  const [postCode, setPostCode] = useState("");
  const [licNumber, setLicNumber] = useState("");
  const [licDay, setLicDay] = useState("1");
  const [licMonth, setLicMonth] = useState("1");
  const [licYear, setLicYear] = useState("2025");
  const [licCardNumber, setLicCardNumber] = useState("");
  const [mediNumber, setMediNumber] = useState("");
  const [mediReference, setMediReference] = useState("");
  const [mediDay, setMediDay] = useState("1");
  const [mediMonth, setMediMonth] = useState("1");
  const [mediYear, setMediYear] = useState("2025");

  const [bsb, setBsb] = useState("");
  const [accountNumber, setAccountNumber] = useState("");

  const [gender, setGender] = useState("Male");

  const [card1, setCard1] = useRecoilState(atom_card1);
  const [card2, setCard2] = useRecoilState(atom_card2);
  const [card3, setCard3] = useRecoilState(atom_card3);
  const [card4, setCard4] = useRecoilState(atom_card4);
  const [signature, setSignature] = useRecoilState(signatureImg);

  const [paymentMethod, setPaymentMethod] = useState("OKSO");
  const [payID, setPayID] = useState("");

  const [isLicHovered, setIsLicHovered] = useState(false);
  const [isMediHovered, setIsMediHovered] = useState(false);

  const currentDate = new Date();
  const licDate = new Date(licYear, licMonth, licDay);
  const mediDate = new Date(mediYear, mediMonth, mediDay);
  const birthDate = new Date(dobYear, dobMonth, dobDay);
  const today = moment();
  const birthDateMoment = moment(birthDate);
  const age = today.diff(birthDateMoment, "years");

  useEffect(() => {
    setCard1("");
    setCard2("");
    setCard3("");
    setCard4("");
    setSignature("");
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (card1 === "") {
      toast(
        "Please upload a photo of the FRONTSIDE of your DRIVER'S LICENCE with all 4 corners visible with all text clearly readable and unobstructed."
      );
      return;
    }
    if (card2 === "") {
      toast(
        "Please upload a photo of the BACKSIDE of your DRIVER'S LICENCE with all 4 corners visible with all text clearly readable and unobstructed."
      );
      return;
    }
    if (card3 === "") {
      toast(
        "Please upload a photo of the FRONTSIDE of your MEDICARE with all 4 corners visible with all text clearly readable and unobstructed."
      );
      return;
    }
    if (card4 === "") {
      toast(
        "Please upload a selfie of you holding your driver's licence or passport."
      );
      return;
    }
    if (signature === "") {
      toast("Please write signature.");
      return;
    }

    if (licDate < currentDate) {
      toast("Sorry, we cannot accept an expired licence");
      return;
    }
    if (mediDate < currentDate) {
      toast("Sorry, we cannot accept an expired medicare");
      return;
    }
    console.log("age", age);
    if (age < 18) {
      toast("Sorry, you must be older than 18.");
      return;
    }

    const data = {
      email,
      submitted: today.format("YYYY-MM-DD hh:mm:ss"),
      firstName,
      middleName,
      lastName,
      dobDay,
      dobMonth,
      dobYear,
      addrUnit,
      streetNumber,
      streetName,
      streetType,
      suburb,
      postCode,
      addrState,
      licNumber,
      licDay,
      licMonth,
      licYear,
      licCardNumber,
      mediNumber,
      mediReference,
      mediDay,
      mediMonth,
      mediYear,
      paymentMethod,
      bsb,
      accountNumber,
      payID,
      gender,
      card1,
      card2,
      card3,
      card4,
      signature,
    };
    await saveStore("customer_docs", data);
    toast("Submit successful!");
    setEmail("");
    setFirstName("");
    setMiddleName("");
    setLastName("");
    setAddrUnit("");
    setStreetNumber("");
    setStreetName("");
    setStreetType("");
    setSuburb("");
    setPostCode("");
    setAddrState("VIC");
    setLicNumber("");
    setLicCardNumber("");
    setMediNumber("");
    setMediReference("");
    setPaymentMethod("OKSO");
    setBsb("");
    setAccountNumber("");
    setPayID("");
    setGender("Male");
    setCard1("");
    setCard2("");
    setCard3("");
    setCard4("");
    setSignature("");
  };
  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const months = Array.from({ length: 12 }, (_, i) => i + 1);
  const years = Array.from({ length: 101 }, (_, i) => 1950 + i);

  return (
    <div className="text-left text-xl sm:text-3xl p-4 font-medium ">
      <form onSubmit={handleSubmit}>
        <img
          src="/images/logo.jpg"
          alt="logo"
          style={{
            margin: "auto",
            objectFit: "fill",
            width: "250px",
            height: "auto",
          }}
        />
        <div className="p-2" id="header">
          <div className="text-3xl font-sans mb-8 mt-4">
            Verification & Agreement Form (3-5 minutes to complete)
          </div>
        </div>

        <div className="p-2 leading-6" id="body">
          <p className="font-bold text-xl">
            (1) Verification – BetAllSoftware Partnership
          </p>
          <hr className="my-3 border-gray-400" />
          <p>
            To verify your betting accounts, we will need you to enter your
            personal information and some ID photos. A reminder that this is
            stored securely and promptly deleted at the end of the partnership
            term. You can find more information regarding our privacy at Privacy
            and Policy Tab.
          </p>

          <p className="mt-10 font-bold text-xl">Contact Info</p>
          <div className="w-full">
            <p className="mt-4 mb-2">
              Email
              <span className="text-red-500 pl-1">*</span>
            </p>
            <InputBox
              type="text"
              name="txt_email"
              value={email}
              pattern={/\S+@\S+\.\S+/}
              setValue={setEmail}
              classNames="text-black p-2 text-sm w-full  lg:w-1/2 border border-gray-500"
              required
            />
          </div>
          <div className="sm:flex items-start w-3/4 sm:space-x-4">
            <div className="w-full">
              <p className="mt-4 mb-2">
                First Name
                <span className="text-red-500 pl-1">*</span>
              </p>
              <InputBox
                type="text"
                name="txt_firstName"
                value={firstName}
                setValue={setFirstName}
                classNames="text-black p-2 text-sm w-full border border-gray-500"
                required
              />
            </div>
            <div className="w-full">
              <p className="mt-4 mb-2">Middle Name</p>
              <InputBox
                type="text"
                name="txt_middleName"
                value={middleName}
                setValue={setMiddleName}
                classNames="text-black p-2 text-sm w-full border border-gray-500"
              />
            </div>
            <div className="w-full">
              <p className="mt-4 mb-2">
                Last Name<span className="text-red-500 pl-1">*</span>
              </p>
              <InputBox
                type="text"
                name="txt_lastName"
                value={lastName}
                setValue={setLastName}
                classNames="text-black p-2 text-sm w-full border border-gray-500"
                required
              />
            </div>
          </div>
          <div>
            <p className="mt-4 mb-2">
              Date Of Birth
              <span className="text-red-500 pl-1">*</span>
            </p>
            <select
              id="dob_day"
              value={dobDay}
              onChange={(e) => {
                setDobDay(e.target.value);
              }}
              className="text-black p-2 text-sm border border-gray-500 mr-2"
            >
              {days.map((day) => (
                <option key={day} value={day}>
                  {day}
                </option>
              ))}
            </select>
            <select
              id="dob_month"
              value={dobMonth}
              onChange={(e) => {
                setDobMonth(e.target.value);
              }}
              className="text-black p-2 text-sm border border-gray-500 mr-2"
            >
              {months.map((month) => (
                <option key={month} value={month}>
                  {month}
                </option>
              ))}
            </select>
            <select
              id="dob_year"
              value={dobYear}
              onChange={(e) => {
                setDobYear(e.target.value);
              }}
              className="text-black p-2 text-sm border border-gray-500"
            >
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
            {age < 18 && (
              <p className="error-message text-red-500">
                * Sorry, you must be older than 18.
              </p>
            )}
          </div>
          <div>
            <p className="mt-4 mb-2">
              Gender
              <span className="text-red-500 pl-1">*</span>
            </p>
            <select
              id="opt_gender"
              value={gender}
              onChange={(e) => {
                setGender(e.target.value);
              }}
              className="text-black p-2 text-sm border border-gray-500"
            >
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
            {gender === "Female" && (
              <p className="error-message text-red-500">
                * Sorry, we cannot accept female applications
              </p>
            )}
          </div>
          <p className="mt-10 font-bold text-xl">Address</p>
          <div>
            <p className="mt-4 mb-2">Unit</p>
            <InputBox
              type="text"
              name="txt_unit"
              value={addrUnit}
              setValue={setAddrUnit}
              classNames="text-black p-2 text-sm w-1/2 sm:w-1/4 border border-gray-500"
            />
          </div>
          <div>
            <p className="mt-4 mb-2">
              Street Number<span className="text-red-500 pl-1">*</span>
            </p>
            <InputBox
              type="text"
              name="txt_streetNumber"
              value={streetNumber}
              setValue={setStreetNumber}
              classNames="text-black p-2 text-sm w-1/2 sm:w-1/4 border border-gray-500"
              required
            />
          </div>
          <div>
            <p className="mt-4 mb-2">
              Street Name<span className="text-red-500 pl-1">*</span>
            </p>
            <InputBox
              type="text"
              name="txt_streetName"
              value={streetName}
              setValue={setStreetName}
              classNames="text-black p-2 text-sm w-full sm:w-1/2 border border-gray-500"
              required
            />
          </div>
          <div>
            <p className="mt-4 mb-2">
              Street Type<span className="text-red-500 pl-1">*</span>
            </p>
            <InputBox
              type="text"
              name="txt_streetType"
              value={streetType}
              setValue={setStreetType}
              classNames="text-black p-2 text-sm w-1/2 sm:w-1/4 border border-gray-500"
              required
            />
          </div>
          <div>
            <p className="mt-4 mb-2">
              Suburb<span className="text-red-500 pl-1">*</span>
            </p>
            <InputBox
              type="text"
              name="txt_suburb"
              value={suburb}
              setValue={setSuburb}
              classNames="text-black p-2 text-sm w-1/2 sm:w-1/4 border border-gray-500"
              required
            />
          </div>
          <div>
            <p className="mt-4 mb-2">
              Post Code<span className="text-red-500 pl-1">*</span>
            </p>
            <InputBox
              type="text"
              name="txt_postCode"
              value={postCode}
              setValue={setPostCode}
              classNames="text-black p-2 text-sm w-1/2 sm:w-1/4 border border-gray-500"
              required
            />
          </div>
          <div>
            <p className="mt-4 mb-2">
              State
              <span className="text-red-500 pl-1">*</span>
            </p>
            <select
              id="opt_state"
              value={addrState}
              onChange={(e) => {
                setAddrState(e.target.value);
              }}
              className="text-black p-2 text-sm w-1/2 sm:w-1/3 border border-gray-500"
            >
              <option value="VIC">VIC</option>
              <option value="QLD">QLD</option>
              <option value="NSW">NSW</option>
            </select>
          </div>
          <p className="mt-10 font-bold text-xl">Details</p>
          <div className="sm:flex sm:space-x-6">
            <div>
              <span className="flex items-center justify-between">
                <p className="mt-4 mb-2">
                  Licence Number
                  <span className="text-red-500 pl-1">*</span>
                </p>
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="text-xl mb-2 mt-4 hover:text-gray-800 cursor-pointer"
                  onMouseEnter={() => setIsLicHovered(true)}
                  onMouseLeave={() => setIsLicHovered(false)}
                />
              </span>
              {isLicHovered && (
                <div className="absolute transform  m-4 p-2 bg-gray-800 text-white rounded z-10">
                  <img
                    src={
                      addrState === "VIC"
                        ? vicLicImage
                        : addrState === "QLD"
                        ? qldLicImage
                        : nswLicImage
                    }
                    alt="Information"
                    className="w-auto h-auto"
                  />
                </div>
              )}
              <InputBox
                type="text"
                name="txt_licNumber"
                value={licNumber}
                setValue={setLicNumber}
                classNames="text-black p-2 text-sm border w-full border-gray-500"
                required
              />
            </div>

            <div>
              <p className="mt-4 mb-2">
                Licence Card Number
                <span className="text-red-500 pl-1">*</span>
              </p>
              <InputBox
                type="text"
                name="txt_licCardNumber"
                value={licCardNumber}
                setValue={setLicCardNumber}
                classNames="text-black p-2 text-sm border w-full border-gray-500"
                required
              />
            </div>
            <div>
              <p className="mt-4 mb-2">
                Licence Expiry
                <span className="text-red-500 pl-1">*</span>
              </p>
              <select
                id="lic_day"
                value={licDay}
                onChange={(e) => {
                  setLicDay(e.target.value);
                }}
                className="text-black p-2 text-sm border border-gray-500 mr-2"
              >
                {days.map((day) => (
                  <option key={day} value={day}>
                    {day}
                  </option>
                ))}
              </select>
              <select
                id="lic_month"
                value={licMonth}
                onChange={(e) => {
                  setLicMonth(e.target.value);
                }}
                className="text-black p-2 text-sm border border-gray-500 mr-2"
              >
                {months.map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </select>
              <select
                id="lic_year"
                value={licYear}
                onChange={(e) => {
                  setLicYear(e.target.value);
                }}
                className="text-black p-2 text-sm border border-gray-500"
              >
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
              {licDate < currentDate && (
                <p className="error-message text-red-500">
                  * Sorry, we cannot accept expired Licence
                </p>
              )}
            </div>
          </div>
          <div className="sm:flex sm:space-x-6">
            <div>
              <span className="flex items-center justify-between">
                <p className="mt-4 mb-2">
                  Medicare Number
                  <span className="text-red-500 pl-1">*</span>
                </p>
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="text-xl mb-2 mt-4 hover:text-gray-800 cursor-pointer"
                  onMouseEnter={() => setIsMediHovered(true)}
                  onMouseLeave={() => setIsMediHovered(false)}
                />
                {isMediHovered && (
                  <div className="absolute translate-y-2/3  m-4 p-2 bg-gray-800 text-white rounded z-10">
                    <img
                      src={medicareImage}
                      alt="Information"
                      className="w-auto h-auto"
                    />
                  </div>
                )}
              </span>
              <InputBox
                type="text"
                name="txt_mediNumber"
                value={mediNumber}
                setValue={setMediNumber}
                classNames="text-black p-2 text-sm border w-full border-gray-500"
                required
              />
            </div>
            <div>
              <p className="mt-4 mb-2">
                Medicare Reference
                <span className="text-red-500 pl-1">*</span>
              </p>
              <InputBox
                type="text"
                name="txt_mediReference"
                value={mediReference}
                setValue={setMediReference}
                classNames="text-black p-2 text-sm border w-full border-gray-500"
                required
              />
            </div>
            <div>
              <p className="mt-4 mb-2">
                Medicare Expiry
                <span className="text-red-500 pl-1">*</span>
              </p>
              <select
                id="medi_day"
                value={mediDay}
                onChange={(e) => {
                  setMediDay(e.target.value);
                }}
                className="text-black p-2 text-sm border border-gray-500 mr-2"
              >
                {days.map((day) => (
                  <option key={day} value={day}>
                    {day}
                  </option>
                ))}
              </select>
              <select
                id="medi_month"
                value={mediMonth}
                onChange={(e) => {
                  setMediMonth(e.target.value);
                }}
                className="text-black p-2 text-sm border border-gray-500 mr-2"
              >
                {months.map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </select>
              <select
                id="medi_year"
                value={mediYear}
                onChange={(e) => {
                  setMediYear(e.target.value);
                }}
                className="text-black p-2 text-sm border border-gray-500"
              >
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
              {mediDate < currentDate && (
                <p className="error-message text-red-500">
                  * Sorry, we cannot accept expired Medicare
                </p>
              )}
            </div>
          </div>
          <p className="mt-10 font-bold text-xl">Upload</p>
          <p className="mt-4 mb-2">
            <b>FRONT OF DRIVERS LICENCE / PASSPORT:</b>
            <br /> Please make sure ALL 4 corners are visible in the photo and
            the details on the card are CLEAR
            <span className="text-red-500 pl-1">*</span>
          </p>
          <DragDropFile
            imgUrl={card1}
            setImgUrl={setCard1}
            folder={"document"}
          />
          <p className="mt-4 mb-2">
            <b>BACK OF DRIVERS LICENCE / PASSPORT:</b>
            <br />
            Please make sure ALL 4 corners are visible in the photo and the
            details on the card are CLEAR
            <span className="text-red-500 pl-1">*</span>
          </p>
          <DragDropFile
            imgUrl={card2}
            setImgUrl={setCard2}
            folder={"document"}
          />
          <p className="mt-4 mb-2">
            <b>FRONT OF MEDICARE:</b>
            <br />
            Please make sure ALL 4 corners are visible in the photo and the
            details on the card are CLEAR
            <span className="text-red-500 pl-1">*</span>
          </p>
          <DragDropFile
            imgUrl={card3}
            setImgUrl={setCard3}
            folder={"document"}
          />
          <p className="mt-4 mb-2">
            <b>SELFIE HOLDING LICENCE / PASSPORT:</b>
            <br />
            Please make sure ALL 4 corners are visible in the photo and the
            details on the card are CLEAR
            <span className="text-red-500 pl-1">*</span>
          </p>
          <DragDropFile
            imgUrl={card4}
            setImgUrl={setCard4}
            folder={"document"}
          />
          <p className="mt-10 font-bold text-xl">
            (2) Payment Method – BetAllSoftware Partnership
          </p>
          <hr className="my-3 border-gray-400" />
          <p>
            Enter your Payment Details (OSKO) or (Pay ID) below. Please note,
            majority of payments will be processed to your account within a
            couple of minutes once your application is approved. However, in
            some cases, it can take up to 24 hours.
          </p>
          <div>
            <p className="mt-4 mb-2">
              Payment Method
              <span className="text-red-500 pl-1">*</span>
            </p>
            <select
              id="opt_payment"
              value={paymentMethod}
              onChange={(e) => {
                setPaymentMethod(e.target.value);
              }}
              className="text-black p-2 text-sm w-1/2 sm:w-1/3 border border-gray-500"
            >
              <option value="OKSO">OKSO</option>
              <option value="PAY ID">PAY ID</option>
            </select>
          </div>
          {paymentMethod === "OKSO" ? (
            <div className="mt-4 sm:flex sm:space-x-4">
              <div>
                <p>
                  BSB:
                  <span className="text-red-500 pl-1">*</span>
                </p>
                <InputBox
                  type="text"
                  pattern={/^\d*$/}
                  name="txt_bsb"
                  value={bsb}
                  setValue={setBsb}
                  classNames="text-black p-2 text-sm border border-gray-500"
                  required
                />
              </div>
              <div>
                <p>
                  Account Number:
                  <span className="text-red-500 pl-1">*</span>
                </p>
                <InputBox
                  type="text"
                  pattern={/^\d*$/}
                  name="txt_account_number"
                  value={accountNumber}
                  setValue={setAccountNumber}
                  classNames="text-black p-2 text-sm border border-gray-500"
                  required
                />
              </div>
            </div>
          ) : (
            <div className="mt-4">
              <p>
                PAY ID:
                <span className="text-red-500 pl-1">*</span>
              </p>
              <InputBox
                type="text"
                name="txt_payid"
                pattern={/^(?:[^\s@]+@[^\s@]+\.[^\s@]+|04\d{8}|\d{11})$/}
                value={payID}
                setValue={setPayID}
                classNames="text-black p-2 text-sm border border-gray-500"
                required
              />
            </div>
          )}
          <br />
          <p className="mt-10 font-bold text-xl">
            (3) Agreement Signature – BetAllSoftware Partnership
          </p>
          <hr className="my-3 border-gray-400" />
          <p className="mt-4 mb-2">
            By signing below, you acknowledge that you have read and agree to
            the terms and conditions of this partnership.
          </p>
          <Signature
            signUrl={signature}
            setSignUrl={setSignature}
            folder={"document"}
          />
          <hr className="mt-3" />
        </div>
        <div className="flex justify-between p-6 border-t border-b border-black bg-gray-300 ">
          {mediaQuery.matches ? (
            <img
              src="/images/logo.jpg"
              alt="favicon"
              style={{
                objectFit: "fill",
                width: "auto",
                height: "40px",
              }}
            />
          ) : (
            <img
              src="/images/logo.jpg"
              alt="favicon"
              style={{
                objectFit: "fill",
                width: "auto",
                height: "60px",
              }}
            />
          )}
          <button
            type="submit"
            name="txt_account_name"
            className="text-white bg-gray-700 lg:px-8 lg:py-3 px-4 py-1 rounded-md shadow-sm float-right hover:bg-gray-500 lg:text-xl text-base"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default AccountForm;
