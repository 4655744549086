import React from "react";
import PageBox from "../../components/common/PageBox";
import Footer from "../../components/home/Footer";
import NavBar from "../../components/navbar/NavBar";
import VideoSection from "../../components/home/VideoSection";
import Promotion_Section1 from "../../components/home/Promotion_Section1";
import Promotion_Section2 from "../../components/home/Promotion_Section2";
import Promotion_Section3 from "../../components/home/Promotion_Section3";
import Promotion_Section4 from "../../components/home/Promotion_Section4";

export default function PromotionPage() {
    return (
        <>
            <NavBar />
            <PageBox>
                <VideoSection
                    url={"/video/phone_video.mp4"}
                    width={300}
                    height={600}
                />
                <Promotion_Section2 />
                <Promotion_Section3 />
                <Promotion_Section4 />
                <Promotion_Section1 />
                {/* <Footer bgType={1} /> */}
            </PageBox>
        </>
    );
}
