import React from "react";
import About from "../../data/about";
import navMenus from "../../data/navMenus";
import { Link } from "react-router-dom";

export default function HomePage() {
    return (
        <div>
            {/* <video
        autoPlay
        playsinline
        loop
        muted
        className="absolute top-0 left-0 z-[-1] w-full h-full object-cover object-center opacity-90"
      >
        <source src="/video/bg.mp4" type="video/mp4" />
      </video> */}
            {/* <img
        src="/images/bg.jpg"
        className="absolute top-0 left-0 z-[-1] w-full h-full object-cover object-center opacity-90"
      /> */}
            <div className=" opacity-80">
                <img
                    src="/images/bg.jpg"
                    alt="profile"
                    className="w-full h-[300px] sm:h-[500px] object-fill"
                    // style={{
                    //   objectFit: "fill",
                    //   width: "100%",
                    //   height: "500px",
                    // }}
                />
            </div>
            <div className="sm:flex sm:justify-around items-center bg-[var(--dialogColor)] opacity-90">
                {navMenus.map((menu, index) => {
                    return (
                        <Link
                            to={menu.path}
                            className="text-white hover:bg-gray-700 w-full h-full cursor-pointer sm:p-10 p-5 sm:text-2xl text-lg font-mono max-sm:block"
                        >
                            {menu.title}
                        </Link>
                    );
                })}
            </div>
            <div className=" sm:p-32 sm:text-5xl text-3xl p-16 font-mono font-semibold">
                Welcome to Bet All Software!
            </div>
        </div>
    );
}
